import {
  Box,
  LoaderSpinner,
  Modal,
  ModalContent,
} from '@lightspeed/design-system-react'
import {
  ChangeCompromisedPasswordModal,
  MFA,
  OTPModal,
  PasswordCompromisedAlertModal,
  RecoveryCodeModal,
} from '@vend/business-components'
import { ErrorPage } from '@vend/react-utilities'
import React, { ReactElement, useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTransifexReady } from '../../../App'
import { Steps, STEPS } from '../../../constants/constants'
import '../../../styles/ratifier.scss'
import { InputCredentials } from '../../../utils/api'
import { useAuthentication } from '../../../utils/useAuthentication'
import { useDarkMode } from '../../../utils/useDarkMode'
import { useLogin } from '../../../utils/useLogin'
import { useRecaptcha } from '../../../utils/useRecaptcha'
import { getDomainPrefix, getVendDomainSuffix } from '../../../utils/util'
import { SigninBackground } from './Background/SigninBackground'
import { ChangePassword } from './ChangePassword/ChangePassword'
import { ConfirmStore } from './ConfirmStore/ConfirmStore'
import { CredentialsAuthentication } from './CredentialsAuthentication/CredentialsAuthentication'
import { NoLoginAccessPage } from './NoLoginAccessPage'

export const Signin = () => {
  useDarkMode()
  const transifexReady = useTransifexReady()
  const {
    showRecaptcha,
    recaptchaKey,
    recaptchaRef,
    getRecaptchaToken,
    handleRecaptchaStatusUpdate,
  } = useRecaptcha()
  const { isLoading, storeName, isSSOEnabled, hasError } = useAuthentication()

  const domainSuffix = getVendDomainSuffix(window.location.href)
  const domainPrefix = getDomainPrefix(domainSuffix)
  const isFromSecureDomain = domainPrefix === 'secure'

  const initialActiveStep = isFromSecureDomain
    ? STEPS.confirmStore
    : STEPS.credentialsAuthentication

  const [activeStep, setActiveStep] = useState(initialActiveStep)
  const [inputCredentials, setInputCredentials] =
    useState<InputCredentials | null>(null)
  const [hasNoLoginAccessError, setHasNoLoginAccessError] = useState(false)

  const showBackgroundImage = [
    STEPS.confirmStore,
    STEPS.credentialsAuthentication,
  ].includes(activeStep)

  const showStep = (step: Steps) => {
    setActiveStep(step)
  }

  const {
    isSubmitting,
    hasInternalError,
    handleSubmit,
    validationError,
    mfaRequired,
    userDisabled,
    noLoginAccess,
  } = useLogin(
    showStep,
    domainPrefix,
    showRecaptcha,
    getRecaptchaToken,
    handleRecaptchaStatusUpdate,
    inputCredentials
  )

  useEffect(() => {
    const urlSearchQuery = window.location.search
    const params = new URLSearchParams(urlSearchQuery)

    const ssoError = params.get('sso_error')
    if (!ssoError) {
      return
    }

    if (ssoError === 'no_login_access') {
      setHasNoLoginAccessError(true)
      params.delete('sso_error')
      window.history.replaceState(null, '', `?${params.toString()}`)
    }
  }, [])

  const handleUsernameChange = (username: string) => {
    setInputCredentials(prev => ({ ...prev, username }))
  }

  const handlePasswordChange = (password: string) => {
    setInputCredentials(prev => ({ ...prev, password }))
  }

  const handleNewPasswordChange = (newPassword: string) => {
    setInputCredentials(prev => ({ ...prev, new_password: newPassword }))
  }

  const handleCompleteMFA = (mfaInput: MFA) => {
    const credentials = { ...inputCredentials, mfa: mfaInput }
    setInputCredentials(credentials)
    handleSubmit(credentials)
  }

  const handleIgnoreCompromisedPassword = () => {
    const credentials = { ...inputCredentials, bypass_hibp: true }
    setInputCredentials(credentials)

    if (mfaRequired) {
      showStep(STEPS.OTPAuthentication)
      return
    }

    handleSubmit(credentials)
  }

  const handleChangeCompromisedPassword = () => {
    if (mfaRequired) {
      showStep(STEPS.OTPAuthentication)
      return
    }

    handleSubmit()
  }

  const renderContent = (): ReactElement => {
    switch (activeStep) {
      case STEPS.confirmStore:
        return <ConfirmStore domainSuffix={domainSuffix} />
      case STEPS.credentialsAuthentication:
        return (
          <CredentialsAuthentication
            isLoading={isLoading}
            storeName={storeName}
            isSSOEnabled={isSSOEnabled}
            domainSuffix={domainSuffix}
            onUsernameChange={handleUsernameChange}
            onPasswordChange={handlePasswordChange}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            isInvalidCredentials={validationError === 'invalidCredentials'}
            hasInternalError={hasInternalError}
            userDisabled={userDisabled}
          />
        )
      case STEPS.OTPAuthentication:
        return (
          <OTPModal
            onClickNoAuthApp={() => setActiveStep(STEPS.recoveryCode)}
            onComplete={handleCompleteMFA}
            isSubmitting={isSubmitting}
            isInvalidOTP={validationError === 'invalidOTP'}
            hasInternalError={hasInternalError}
          />
        )
      case STEPS.recoveryCode:
        return (
          <RecoveryCodeModal
            onBack={() => showStep(STEPS.OTPAuthentication)}
            onSubmit={handleCompleteMFA}
            isSubmitting={isSubmitting}
            isInvalidCode={validationError === 'invalidRecoveryCode'}
          />
        )
      case STEPS.changePassword:
        return (
          <ChangePassword
            inputCredentials={inputCredentials!}
            showFirstStep={() => showStep(STEPS.credentialsAuthentication)}
            requireRecaptcha={showRecaptcha}
            onRecaptchaStatusUpdate={handleRecaptchaStatusUpdate}
            getRecaptchaToken={getRecaptchaToken}
          />
        )
      case STEPS.PasswordCompromisedAlert:
        return (
          <PasswordCompromisedAlertModal
            isSubmitting={isSubmitting}
            onChangePassword={() => showStep(STEPS.changeCompromisedPassword)}
            onIgnore={handleIgnoreCompromisedPassword}
          />
        )
      case STEPS.changeCompromisedPassword:
        return (
          <ChangeCompromisedPasswordModal
            onCancel={() => showStep(STEPS.PasswordCompromisedAlert)}
            onNewPasswordChange={handleNewPasswordChange}
            isSubmiting={isSubmitting}
            onSubmit={handleChangeCompromisedPassword}
          />
        )
      default:
        return <></>
    }
  }

  if (hasError) {
    return (
      <section className="vr-container">
        <main className="vd-flex vd-flex--align-center vd-flex--justify-center">
          <ErrorPage />
        </main>
      </section>
    )
  }

  if (noLoginAccess || hasNoLoginAccessError) {
    return <NoLoginAccessPage />
  }

  return (
    <section className="vr-container">
      {showBackgroundImage && <SigninBackground />}
      <main className="vd-flex vd-flex--align-center vd-flex--justify-center vr-main">
        <div className="vd-flex vd-flex--justify-center">
          {showRecaptcha && (
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={recaptchaKey}
            />
          )}
          {!transifexReady && (
            <Modal focusTrapActive={false} pageModal size="small">
              <ModalContent>
                <Box
                  css={{
                    width: '100%',
                    height: '350px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <LoaderSpinner />
                </Box>
              </ModalContent>
            </Modal>
          )}
          {transifexReady && renderContent()}
        </div>
      </main>
    </section>
  )
}
