import { useMemo, useSyncExternalStore } from 'react'
import { t, TxNative } from '@transifex/native'
import {
  TXInstanceCache,
  TXManagerResult,
  createTxInstanceCache,
} from '@vend/utilities'
import {
  HELIOS_TRANSIFEX_TAG,
  HELIOS_TRANSIFEX_TOKEN,
  overrideHeliosTXInstance,
} from '@lightspeed/design-system-react'

declare global {
  interface Window {
    System?: any
  }
}

let instance: TXInstanceCache | null = null

let listeners: Array<() => void> = []

const addListener = (listener: () => void) => {
  listeners = [...listeners, listener]
}

const removeListener = (listener: () => void) => {
  listeners = listeners.filter(l => l !== listener)
}

const updateListeners = () => {
  listeners.forEach(listener => {
    listener()
  })
}

const getInstance = () => instance

const subscribe = (cb: () => void) => {
  addListener(cb)
  return () => removeListener(cb)
}

const loadInstance = async () => {
  try {
    instance = await window.System.import('@vend-spa/tx-manager')
    updateListeners()
  } catch {
    instance = createTxInstanceCache()
    updateListeners()
  }
}

loadInstance()

export const useTXManager = (
  tag: string,
  token?: string,
  preloadTags?: string[]
): TXManagerResult => {
  const instanceCache = useSyncExternalStore<TXInstanceCache | null>(
    subscribe,
    getInstance
  )

  const result = useMemo<TXManagerResult>(() => {
    if (!instanceCache) {
      return {
        txInstance: {
          t,
          setCurrentLocale: () => Promise.resolve(),
          controllerOf: () => {},
          init: () => {},
        } as unknown as TxNative,
      }
    }

    if (preloadTags && preloadTags.length) {
      preloadTags.forEach(tag => {
        const isHelios = tag === HELIOS_TRANSIFEX_TAG
        const instance = instanceCache.getTxInstance(
          tag,
          isHelios ? HELIOS_TRANSIFEX_TOKEN : token
        )

        if (isHelios) {
          overrideHeliosTXInstance(instance)
        }
      })
    }

    return {
      txInstance: instanceCache.getTxInstance(tag, token),
      ...instanceCache,
    }
  }, [preloadTags, tag, token, instanceCache])

  return result
}
