import { ToastNotifications } from '@lightspeed/design-system-react'
import { ErrorBoundary } from '@vend/react-utilities'
import { getClientType } from '@vend/business-components'
import React, { useContext, useEffect } from 'react'

import { BrowserRouter } from 'react-router-dom'
import { TXProvider } from '@transifex/react'
import { Topbar } from './components/common/Topbar/Topbar'
import { Routes } from './components/Routes/Routes'
import { CookiesDisabled } from './components/views/CookiesDisabled/CookiesDisabled'
import { getRetailDomain } from './utils/getRetailDomain'
import { useRatifierTransifex } from './utils/useRatifierTransifex'

const TransifexReadyContext = React.createContext(false)

export const useTransifexReady = () => {
  return useContext(TransifexReadyContext)
}

export const App: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { txInstance, isReady } = useRatifierTransifex()

  useEffect(() => {
    const prefix = window.location.hostname.split('.')[0]
    const clientType = getClientType()
    if (clientType === 'ios' || clientType === 'android') {
      return
    }
    const domain = getRetailDomain()
    if (!window.location.hostname.includes(domain)) {
      const newUrl = new URL(window.location.toString())
      newUrl.hostname = `${prefix}${domain}`
      window.location.replace(newUrl.toString())
    }
  }, [])

  const cookiesEnabled = navigator.cookieEnabled

  return (
    <ErrorBoundary>
      <ToastNotifications />
      <Topbar />
      <TXProvider instance={txInstance}>
        <TransifexReadyContext.Provider value={isReady}>
          {cookiesEnabled && (
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          )}
          {!cookiesEnabled && <CookiesDisabled />}
        </TransifexReadyContext.Provider>
      </TXProvider>
    </ErrorBoundary>
  )
}
