import { ApiUser } from '@vend/types'

import { fetchApiJson } from '../api/fetchApiJson'
import { VEND_NS } from './vendNamespace'

/**
 * Fetches the current logged in user.
 *
 * If a fetch is in progress it returns a promise to fetch the user.
 * If the user has already been fetched it returns the existing user object.
 */
export async function getUser(): Promise<ApiUser> {
  if (VEND_NS.user) {
    return VEND_NS.user
  }

  if (!VEND_NS.userPromise) {
    VEND_NS.userPromise = fetchApiJson('/api/2.0/user')
  }

  const userApiResult = await VEND_NS.userPromise

  VEND_NS.user = userApiResult.data

  return VEND_NS.user
}
