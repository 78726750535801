import { detectCurrentLocale } from './detectCurrentLocale'
import { DEFAULT_LOCALE, isLocaleSupported } from './supportedLocales'

export const I18N_STORAGE_KEY = 'xseries-i18n'

export type I18nStorage = { currentLocale?: string; localeForIntl?: string }

/**
 * Get the locale based on our supported locales (for transifex)
 * and preferred languages of the current user.
 *
 * @returns the currently active locale for translating via transifex, in xx_YY format e.g. en_US
 */
export const getTransifexLocale = (): string => {
  let { currentLocale } = getFromStorage<I18nStorage>(I18N_STORAGE_KEY) || {}

  if (!currentLocale || !isLocaleSupported(currentLocale)) {
    currentLocale = detectCurrentLocale()
    // Intentionally omitting localeForIntl here so that we can re-resolve an appropriate locale based on the updated
    // currentLocale being set here
    saveToStorage(I18N_STORAGE_KEY, { currentLocale })
  }

  return currentLocale
}

/**
 * Get the locale to use for Intl APIs (except Intl.DateTimeFormat - use formatDateTime util instead)
 * based on our supported locales and the preferred languages of the current user. The locale returned may not be
 * one of our officially supported locales, but will be a supported language (for example this could return en-CA as
 * the locale even though en-CA is not officially supported, the official locale for that language is en-US).
 *
 * @param retailerCulture The retailer's configured culture e.g. en-NZ
 *
 * @returns the locale to use for Intl formatting APIs (e.g. number, currency) in IETF (xx-YY) format e.g. en-CA
 */
export const getLocaleForIntl = (retailerCulture?: string): string => {
  const txLocale = getTransifexLocale()
  let { localeForIntl } = getFromStorage<I18nStorage>(I18N_STORAGE_KEY) || {}

  if (localeForIntl) {
    // We have previously resolved localeForIntl and it wasn't cleared by querying getTransifexLocale
    return localeForIntl
  }

  localeForIntl = ISOLocaleToIETF(txLocale)
  if (retailerCulture && txLocale === DEFAULT_LOCALE) {
    localeForIntl = retailerCulture
  }

  const txLang = txLocale.split('_')[0]
  const preferredLocales = navigator.languages

  preferredLocales.some(preferredLocale => {
    // Only consider full locales (e.g. en-US) not base languages (e.g. en). This way we fall back on the retailer
    // culture or current transifex locale if there is only a base language set in the browser
    if (preferredLocale === txLang) {
      return false
    }
    const lang = IETFLocaleToISO(preferredLocale).split('_')[0]
    if (lang === txLang) {
      localeForIntl = preferredLocale
      return true
    }
    return false
  })

  saveToStorage(I18N_STORAGE_KEY, { currentLocale: txLocale, localeForIntl })
  return localeForIntl
}

/** @deprecated use getTransifexLocale */
export const getCurrentLocale = (): string => getTransifexLocale()

/** @deprecated Use getLocaleForIntl for Intl APIs except Intl.DateTimeFormat for which use formatDateTime. */
export const getCurrentLocaleForIntl = (): string => {
  return ISOLocaleToIETF(getTransifexLocale())
}

export function ISOLocaleToIETF(locale: string): string {
  return locale.replace('_', '-')
}

function IETFLocaleToISO(languageCode: string): string {
  return languageCode.replace('-', '_')
}

export const getFromStorage = <T extends unknown = unknown>(
  key: string
): T | undefined => {
  try {
    const item = sessionStorage.getItem(key)
    return item && JSON.parse(item)
  } catch (e) {
    return
  }
}

export const saveToStorage = (key: string, data: any): void => {
  try {
    sessionStorage.setItem(key, JSON.stringify(data))
  } catch (e) {}
}
