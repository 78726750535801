import { isProduction } from '../environment'
import { VEND_NS } from '../data/vendNamespace'
import { SystemConfig } from '../types/SystemConfig'
import { camelToSnake } from '../utilities/case'
import { Deferred } from '../utilities/deferred'

const TRACKING_API = 'https://track.api.vendhq.com/'
const COMPULSORY_TRACKER_PROPERTIES: Array<keyof UBEConfig> = [
  'domainPrefix',
  'retailerId',
  'systemName',
  'systemVersion',
  'userId',
]

interface UBEvent {
  deviceFingerprint?: string
  domainPrefix: string
  eventName: string
  metadata: {}
  moduleName: string
  retailerId: string
  systemName: string
  systemVersion: string | number
  timestamp: number
  userId: string
}

export type UBE = Pick<UBEvent, 'eventName' | 'moduleName' | 'metadata'>

export interface UBEConfig extends SystemConfig {
  retailerId: string
  userId: string
}

export interface UBEOptions {
  // Whether navigator.sendBeacon should be used (if possible) for transmitting the event. Use with small
  // amounts of data and for events triggered just before the document is unloaded.
  useSendBeacon: boolean
}

// Create the deferred for the UBEConfig if it doesn't already exist on the window (i.e. created by another script)
if (!VEND_NS.ubeConfigDeferred) {
  VEND_NS.ubeConfigDeferred = new Deferred()
}

/**
 * Configure UBE tracking by with the given UBEConfig. UBE events will be queued up until a minimum of the following
 * config properties are set: domainPrefix, retailerId, systemName, systemVersion and userId.
 */
export function configureUbeTracking(config: Partial<UBEConfig>) {
  VEND_NS.ubeConfig = {
    ...VEND_NS.ubeConfig,
    ...config,
  }
  if (COMPULSORY_TRACKER_PROPERTIES.every(prop => VEND_NS.ubeConfig![prop])) {
    VEND_NS.ubeConfigDeferred!.resolve()
  }

  return VEND_NS.ubeConfigDeferred!.promise
}

async function getUbeConfig(): Promise<UBEConfig> {
  // Wait until the minimum required configuration has been supplied before proceeding
  await VEND_NS.ubeConfigDeferred!.promise
  return VEND_NS.ubeConfig! as UBEConfig
}

/**
 * Sends a User Behaviour Event (UBE) to the event tracking API.
 */
export async function trackUserEvent(
  moduleName: string,
  eventName: string,
  metadata?: {} | null,
  options?: UBEOptions
): Promise<any> {
  const { environment, ...eventConfig } = await getUbeConfig()

  const event: UBEvent = {
    ...eventConfig,
    eventName,
    metadata: { location: window.location.pathname, ...metadata },
    moduleName,
    timestamp: Date.now(),
  }

  if (isProduction(window.location.href, environment)) {
    return _sendTrackingData(event, options)
  } else {
    // eslint-disable-next-line no-console
    console.info('[Tracking Vend Event]', camelToSnake(event))
    return Promise.resolve()
  }
}

function _sendTrackingData(event: UBEvent, options?: UBEOptions): Promise<any> {
  const body = JSON.stringify(camelToSnake(event))

  if (options && options.useSendBeacon && window.navigator.sendBeacon) {
    const beaconBlob = new Blob([body], { type: 'text/plain' })
    return Promise.resolve(
      window.navigator.sendBeacon(TRACKING_API, beaconBlob)
    )
  }

  return fetch(TRACKING_API, {
    body,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    method: 'POST',
  })
}
