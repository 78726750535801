import IMAGES_CDN_PATH from '@vend/images-ui'
import React, { useMemo } from 'react'
import classNames from 'classnames'

interface Props {
  /** The alternative text for the image */
  alt?: string
  /** Classes to be applied to the image */
  className?: string
  /** Function to be called when the image is loaded, will get fired upon a theme change if an alternative dark theme image src is provided. */
  onLoad?: () => void
  /** The relative path to the image. https://github.com/vend/images-ui/tree/master/images forms the base path so "add-ons/my-addon-img.png" would be inside /images/add-ons.  */
  relativeImagePath: string
  /** If provided this image will be used when the ui is in dark mode. */
  relativeDarkImagePath?: string
  /** The width of the image */
  width?: string | number
  /** Adds a class which makes the image behave responsively */
  responsive?: boolean
}

const RESPONSIVE_IMAGE_CLASS = 'vd-util-responsive-img'

const trimInitialAndTrailingSlashes = (relativePath: string): string => {
  return relativePath.replace(/^\/|\/$/g, '')
}

const createAbsolutePath = (relativePath: string): string => {
  return IMAGES_CDN_PATH + trimInitialAndTrailingSlashes(relativePath)
}

const VendImage = ({
  alt = '',
  className,
  onLoad,
  relativeImagePath,
  relativeDarkImagePath,
  responsive,
  width,
}: Props) => {
  const lightImagePath = createAbsolutePath(relativeImagePath)
  const imgClasses = classNames(className, {
    [RESPONSIVE_IMAGE_CLASS]: responsive,
  })

  const isDarkMode = useMemo(() => {
    const isDarkModeSupported =
      document.body.classList.contains('vd-theme--use-dark-mode') ||
      document.body.classList.contains('vd-theme--dark')

    const prefersDarkScheme = window.matchMedia?.(
      '(prefers-color-scheme: dark)'
    )?.matches

    return isDarkModeSupported && prefersDarkScheme
  }, [])

  const imagePath =
    isDarkMode && relativeDarkImagePath
      ? createAbsolutePath(relativeDarkImagePath)
      : lightImagePath

  return (
    <img
      alt={alt}
      src={imagePath}
      className={imgClasses}
      onLoad={onLoad}
      width={width}
    />
  )
}

export { VendImage }
