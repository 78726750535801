import {
  DEFAULT_LOCALE,
  SUPPORTED_LOCALES_BY_LANGUAGE,
} from './supportedLocales'

/**
 * Detect the current locale among our supported locales
 * based on the preferred languages of the user.
 */
export const detectCurrentLocale = (): string => {
  const preferredLocales = navigator.languages

  let resolvedLocale: string = DEFAULT_LOCALE

  preferredLocales.some(preferredLocale => {
    const locale = preferredLocale.replace('-', '_')
    const lang = locale.split('_')[0]

    const langSupportedLocales = SUPPORTED_LOCALES_BY_LANGUAGE[lang]

    // The language is supported
    if (langSupportedLocales) {
      // The locale is supported
      if (langSupportedLocales.includes(locale)) {
        resolvedLocale = locale
        return true
      }
      // We fall back to the first locale of the language
      resolvedLocale = langSupportedLocales[0]
      return true
    }
    return false
  })

  return resolvedLocale
}
