import React, { PropsWithoutRef, RefAttributes } from 'react'
import { TransifexManager } from '@vend/react-utilities'

export const BUSINESS_COMPONENTS_TRANSLATION_TAG = 'monocle-business-components'

const withTranslations = <P extends object>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: React.FC<React.PropsWithChildren<P>>,
  displayName?: string
): React.FC<React.PropsWithChildren<P>> => {
  const TranslatedComponent: React.FC<React.PropsWithChildren<P>> = props => {
    return (
      <TransifexManager tag={BUSINESS_COMPONENTS_TRANSLATION_TAG}>
        <Component {...props} />
      </TransifexManager>
    )
  }
  const componentName =
    // eslint-disable-next-line i18next/no-literal-string
    displayName || Component.displayName || Component.name || 'Component'
  TranslatedComponent.displayName = componentName
  return TranslatedComponent
}

/**
 * Equivalent to withTranslations but for components that expose a ref API e.g. via useImperativeHandle
 * or React.forwardRef.
 */
const withTranslationsForwardRef = <R extends any, P extends object>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component:
    | React.FC<React.PropsWithChildren<P>>
    | React.ForwardRefExoticComponent<P>,
  displayName?: string
): React.ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<R>> => {
  const TranslatedComponent = React.forwardRef<R, P>((props, ref) => {
    return (
      <TransifexManager tag={BUSINESS_COMPONENTS_TRANSLATION_TAG}>
        <Component ref={ref} {...props} />
      </TransifexManager>
    )
  })
  const componentName =
    // eslint-disable-next-line i18next/no-literal-string
    displayName || Component.displayName || Component.name || 'Component'
  TranslatedComponent.displayName = componentName
  return TranslatedComponent
}

export { withTranslations, withTranslationsForwardRef }
