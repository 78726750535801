import { ApiRetailer } from '@vend/types'

import { fetchApiJson } from '../api/fetchApiJson'
import { VEND_NS } from './vendNamespace'

/**
 * Fetches the current logged in retailer.
 *
 * If a fetch is in progress it returns a promise to fetch the retailer.
 * If the retailer has already been fetched it returns the existing retailer object.
 * @param {object} { refetch: boolean } - refetch: true - will always fetch retailer data
 */
export async function getRetailer(
  { refetch } = { refetch: false }
): Promise<ApiRetailer> {
  if (VEND_NS.retailer && !refetch) {
    return VEND_NS.retailer
  }

  if (!VEND_NS.retailerPromise || refetch) {
    VEND_NS.retailerPromise = fetchApiJson('/api/2.0/retailer')
  }

  const retailerApiResult = await VEND_NS.retailerPromise

  VEND_NS.retailer = retailerApiResult.data

  return VEND_NS.retailer
}
