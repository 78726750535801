import { RaygunPayload } from 'raygun4js'
import React from 'react'
import ReactDOMClient from 'react-dom/client'
import { initRaygun, initDatadog } from '@vend/utilities'
import { App } from './App'

import '@lightspeed/design-system-css'

// Initialize Raygun client
initRaygun('SmcSIqr3wHvFwjvbzwEFw', {
  domainPrefix: window.location.hostname,
  environment: process.env.NODE_ENV,
  systemName: 'monocle-ratifier',
  systemVersion: process.env.REACT_APP_SYSTEM_VERSION || '1.0',
  onBeforeSend: (payload: RaygunPayload) => {
    const message = payload.Details.Error.Message

    if (
      message.includes(`document.getElementById('signin_password')`) ||
      message.includes(`document.getElementById('signin_username')`)
    ) {
      return false
    }

    return payload
  },
})

initDatadog({
  applicationName: 'ratifier',
  applicationId: '01ca27f7-8194-4568-87d7-115edb1d680c',
  clientToken: 'pubcc17f498eab1f857b5b700964ad0c491',
  authenticated: false,
})

const domElement = document.getElementById('react-root')

if (domElement) {
  ReactDOMClient.createRoot(domElement).render(<App />)
}
