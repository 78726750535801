import { T, useT } from '@transifex/react'
import React from 'react'
import { VendImage } from '../vendImage/VendImage'
import { withTranslations } from '../../tx'

const NotFoundPageUnconnected: React.FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  const t = useT()
  return (
    <section
      data-testid="NotFoundPage"
      className="vd-error-main vd-align-center"
      style={{ marginTop: '100px' }}
    >
      <div className="vd-flex vd-flex--column vd-flex--align-center vd-pt10">
        <VendImage
          relativeImagePath="error/something-went-wrong-v3.svg"
          alt={t('Something went wrong', { _context: 'NotFoundPage' })}
        />
        <section className="vd-section">
          <div className="vd-section-wrap">
            <h1 className="vd-header vd-header--section">
              <T _str="We can’t find the page you’re looking for." />
            </h1>
            <p className="vd-text--sub">
              <T _str="This page is no longer available, or an invalid URL has been entered." />
            </p>
          </div>
        </section>
      </div>
    </section>
  )
}

export const NotFoundPage = withTranslations(
  NotFoundPageUnconnected,
  'NotFoundPage'
)
