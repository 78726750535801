import { formatPercentage } from './formatNumber'

/**
 * Takes a decimal fraction representing a tax rate and converts to a percentage
 * with at least 2 and no more than 4 decimal places.
 *
 * @param rate {string | number} Tax rate as a decimal fraction
 * @param culture {string} retailer culture
 */

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
}

export const formatRateAsPercent = (
  rate: number | string,
  culture?: string
) => {
  return formatPercentage(rate, culture, options)
}
