/**
 * Toggles app theme between dark and light mode.
 *
 * @example
 * (in browser console) __devtools.toggleTheme()
 */
export const toggleTheme = () => {
  const body = document.querySelector('body')
  if (body === null) {
    throw new Error('huh body element not found?')
  }

  body.classList.toggle('vd-theme--dark')
}
