import { ApiUser } from '@vend/types'
import { trackError } from '../tracking/errorTracker'

let channel: BroadcastChannel | null = null

type UserSwitchMessage = {
  action: 'user_switch'
  user: ApiUser
}

const getChannel = (): BroadcastChannel => {
  if (!channel) {
    channel = new BroadcastChannel('user')
  }

  return channel
}

// Broadcast a user switch event across tabs
export const broadcastUserSwitchEvent = (user: ApiUser) => {
  const message: UserSwitchMessage = { action: 'user_switch', user }
  getChannel().postMessage(message)
}

// Subscribe to user switch events and handle them with the provided callback
export const subscribeToUserSwitchEvents = (
  onSwitch: (user: ApiUser) => void
) => {
  const userchannel = getChannel()

  userchannel.onmessage = event => {
    if (event.data.action === 'user_switch') {
      onSwitch(event.data.user)
    }
  }

  userchannel.onmessageerror = event => {
    trackError('error receiving user switch event', event)
  }

  // Return a cleanup function
  return () => {
    if (channel) {
      channel.close()
      channel = null
    }
  }
}
