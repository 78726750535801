import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Text,
} from '@lightspeed/design-system-react'
import { useT } from '@transifex/react'
import { trackUserEvent } from '@vend/utilities'
import React from 'react'
import { withTranslations } from '../../tx'
import { UBE_MODULE_COMPROMISED_PASSWORD } from './constants'

interface PasswordCompromisedAlertModalProps {
  isSubmitting: boolean
  onChangePassword: () => void
  onIgnore: () => void
  onClose?: () => void
  authType?: 'signin' | 'userSwitch'
}

const PasswordCompromisedAlertModalUnconnected: React.FC<
  PasswordCompromisedAlertModalProps
> = ({
  isSubmitting,
  onChangePassword,
  onIgnore,
  onClose,
  authType = 'signin',
}) => {
  const t = useT()

  const handleIgnore = async () => {
    trackUserEvent(UBE_MODULE_COMPROMISED_PASSWORD, 'Not Now Clicked')
    onIgnore()
  }

  const isSignin = authType === 'signin'

  return (
    <Modal
      focusTrapActive={!isSignin}
      size="small"
      pageModal={isSignin}
      onClose={onClose}
    >
      <ModalHeader>
        <div className="vd-flex vd-flex--align-center">
          <div className="vd-text-heading">
            {t('Change your password to keep your account secure', {
              _context: 'password compromised alert - modal header',
            })}
          </div>
        </div>
      </ModalHeader>

      <ModalContent>
        <Text>
          {t(
            'Lightspeed Retail has found your password in a third-party data breach, which puts your account at risk of being compromised. We recommend changing your password immediately.',
            { _context: 'password compromised alert - modal content' }
          )}
        </Text>
      </ModalContent>
      <ModalActions className="right-align">
        <>
          <Button
            className="vd-mr2"
            variant="supplementary"
            onClick={handleIgnore}
            loading={isSubmitting}
          >
            {t('Not now', {
              _context: 'password compromised alert - modal action button',
            })}
          </Button>
          <Button
            variant="go"
            onClick={onChangePassword}
            disabled={isSubmitting}
            autoFocus
          >
            {t('Change password', {
              _context: 'password compromised alert - modal action button',
            })}
          </Button>
        </>
      </ModalActions>
    </Modal>
  )
}

export const PasswordCompromisedAlertModal = withTranslations(
  PasswordCompromisedAlertModalUnconnected,
  // eslint-disable-next-line i18next/no-literal-string
  'PasswordCompromisedAlert'
)
