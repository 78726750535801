import { ApiResponse, ApiRetailer, ApiUser } from '@vend/types'
import { UBEConfig } from '../tracking/ubeTracker'
import { Deferred } from '../utilities/deferred'

interface VendNamespace {
  retailerPromise?: Promise<ApiResponse<ApiRetailer>>
  retailer?: ApiRetailer
  userPromise?: Promise<ApiResponse<ApiUser>>
  user?: ApiUser
  ubeConfig?: Partial<UBEConfig>
  ubeConfigDeferred?: Deferred<void>
}

declare global {
  interface Window {
    __Vend: VendNamespace
  }
}

/*
 * Create a global namespace for storing shared state/etc between components/scripts on the page.
 */
window.__Vend = window.__Vend || {}
export const VEND_NS: VendNamespace = window.__Vend
