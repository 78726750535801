const LOCAL_STORAGE_TEST_KEY = 'local_storage_test_key'

class StubStorage {
  private items: Record<string, any>

  constructor() {
    this.items = {}
  }

  public getItem(key: string) {
    return this.items[key]
  }

  public setItem(key: string, value: any) {
    this.items[key] = value
  }

  public removeItem(key: string) {
    delete this.items[key]
  }

  public clear() {
    this.items = {}
  }
}

const isLocalStorageAvailable = () => {
  if (!('localStorage' in window)) {
    return false
  }

  try {
    localStorage.setItem(LOCAL_STORAGE_TEST_KEY, '')
    localStorage.removeItem(LOCAL_STORAGE_TEST_KEY)
  } catch {
    // Most likely iOS Safari in private browsing mode whereby localStorage is available but errors if you use it
    return false
  }

  return true
}

const storage = isLocalStorageAvailable() ? localStorage : new StubStorage()

export const saveToStorage = (key: string, data: any) =>
  storage.setItem(key, JSON.stringify(data))

export const getFromStorage = <T extends unknown = unknown>(
  key: string
): T | undefined => {
  const content = storage.getItem(key)
  if (!content) {
    return
  }
  try {
    return JSON.parse(content)
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.warn(`Failed to parse data for key '${key}'`, e)
  }
  return
}

export const removeFromStorage = (key: string) => storage.removeItem(key)

export const clearStorage = () => storage.clear()
