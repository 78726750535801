import {
  Password,
  PasswordAutocompleteOptions,
  PasswordStatus,
} from '@lightspeed/design-system-react'
import { useT } from '@transifex/react'
import { trackError } from '@vend/utilities'
import React, { useCallback, useEffect, useState } from 'react'
import { withTranslations } from '../../tx'

type NewPasswordProps = {
  onPasswordChange: (password: string, isValid: boolean) => void
  hasClickedSubmit: boolean
}

const isPasswordValid = (
  password: string,
  passwordStatus: PasswordStatus,
  repeatPassword: string
): boolean => {
  return password === repeatPassword && passwordStatus.isValid
}

const NewPasswordUnconnected: React.FC<NewPasswordProps> = ({
  onPasswordChange: onValidPasswordChange,
  hasClickedSubmit,
}) => {
  const t = useT()

  const [mainPassword, setMainPassword] = useState<string>('')
  const [mainPasswordStatus, setMainPasswordStatus] = useState<PasswordStatus>({
    isValid: false,
    validationResults: {
      hasMinimumLength: false,
      hasMinimumStrength: false,
    },
  })
  const [repeatPassword, setRepeatPassword] = useState<string>('')
  const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] =
    useState<string>('')
  const [repeatPasswordTainted, setRepeatPasswordTainted] =
    useState<boolean>(false)

  const updateRepeatError = useCallback(
    (password: string, repeat: string): void => {
      if (password !== repeat) {
        setRepeatPasswordErrorMessage(t('The passwords do not match'))
        return
      }

      setRepeatPasswordErrorMessage('')
    },
    [t]
  )

  useEffect(() => {
    if (hasClickedSubmit || repeatPasswordTainted) {
      updateRepeatError(mainPassword, repeatPassword)
    }
  }, [
    hasClickedSubmit,
    mainPassword,
    repeatPassword,
    repeatPasswordTainted,
    updateRepeatError,
  ])

  const handlePasswordChange = (
    changedMainPassword: string,
    status: PasswordStatus
  ): void => {
    setMainPasswordStatus(status)
    setMainPassword(changedMainPassword)

    onValidPasswordChange(
      changedMainPassword,
      isPasswordValid(changedMainPassword, status, repeatPassword)
    )

    if (hasClickedSubmit || repeatPasswordTainted) {
      updateRepeatError(changedMainPassword, repeatPassword)
    }
  }

  const handleRepeatPasswordChange = (changedRepeatPassword: string): void => {
    setRepeatPasswordTainted(true)
    setRepeatPassword(changedRepeatPassword)
    updateRepeatError(mainPassword, changedRepeatPassword)

    onValidPasswordChange(
      mainPassword,
      isPasswordValid(mainPassword, mainPasswordStatus, changedRepeatPassword)
    )
  }

  return (
    <div>
      <div className="vd-field">
        <Password
          label={t('New password', {
            _context: 'new password component input lable',
          })}
          placeholder={t('Enter new password', {
            _context: 'new password component input placeholder',
          })}
          autoComplete={PasswordAutocompleteOptions.NewPassword}
          onPasswordChange={handlePasswordChange}
          forceTaint={hasClickedSubmit}
          showStrengthMeter
          showStrengthHints
          enableZxcvbn={true}
          trackError={trackError}
        />
      </div>

      {/* Repeat password */}
      <div className="vd-field">
        <Password
          // We don't check the minimum length on the repeat password input.
          minimumLength={0}
          label={t('Confirm password', {
            _context: 'new password component input lable',
          })}
          autoComplete={PasswordAutocompleteOptions.NewPassword}
          placeholder={t('Enter new password again', {
            _context: 'new password component input placeholder',
          })}
          onPasswordChange={handleRepeatPasswordChange}
          overriddenErrorMessage={repeatPasswordErrorMessage}
          forceTaint={hasClickedSubmit}
          enableZxcvbn={false}
        />
      </div>
    </div>
  )
}

export const NewPassword = withTranslations(
  NewPasswordUnconnected,
  // eslint-disable-next-line i18next/no-literal-string
  'NewPassword'
)
