import {
  isLspdDomainName,
  isProduction,
  isProductionDomain,
} from '@vend/utilities'
import {
  RECAPTCHA_DEV_SITE_KEY,
  RECAPTCHA_PUBLIC_SITE_KEY,
  RECAPTCHA_TEST_SITE_KEY,
  VEND_DOMAIN_SUFFIX,
  VendDomainSuffix,
} from '../constants/constants'

export const getVendDomainSuffix = (location: string): VendDomainSuffix => {
  const isDev = !isProductionDomain(location)
  if (isLspdDomainName(location)) {
    return isDev ? VEND_DOMAIN_SUFFIX.lspdDev : VEND_DOMAIN_SUFFIX.lspdProd
  }
  return isDev ? VEND_DOMAIN_SUFFIX.vendDev : VEND_DOMAIN_SUFFIX.vendProd
}

// Extract the prefix on the environments domain, e.g. catsandhats.vendhq.com
export const getDomainPrefix = (domainSuffix: string): string => {
  return window.location.hostname.substring(
    0,
    window.location.hostname.indexOf(domainSuffix)
  )
}

export const getRecaptchaKey = (): string => {
  if (isProduction(window.location.href)) {
    return RECAPTCHA_PUBLIC_SITE_KEY
  }

  if ('Cypress' in window) {
    return RECAPTCHA_TEST_SITE_KEY
  }

  return RECAPTCHA_DEV_SITE_KEY
}
