import { LoaderSpinner } from '@lightspeed/design-system-react'
import { ErrorPage, NotFoundPage } from '@vend/react-utilities'
import { trackError } from '@vend/utilities'
import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { PAGE_ROUTE } from '../../../constants/constants'
import {
  checkDomainPrefix,
  verifyTokenId,
  VerifyTokenIDResponse,
} from '../../../utils/api'
import { getVendDomainSuffix } from '../../../utils/util'

export const ResetPasswordVerifyToken = () => {
  const { token } = useParams<{ token: string }>()
  const history = useHistory()

  const domainSuffix = getVendDomainSuffix(window.location.href)
  // Extract the prefix on the environments domain, e.g. catsandhats.vendhq.com
  const domainPrefix = window.location.hostname.substring(
    0,
    window.location.hostname.indexOf(domainSuffix)
  )

  const [isLoading, setIsLoading] = useState(true)
  const [isNotFound, setIsNotFound] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [tokenResp, setTokenResp] = useState<VerifyTokenIDResponse>()

  useEffect(() => {
    Promise.all([
      checkDomainPrefix(domainPrefix),
      verifyTokenId({ token_id: token }),
    ])
      .then(([domainPrefixResp, tokenResp]) => {
        if (!domainPrefixResp.valid) {
          window.location.assign(
            `https://secure${domainSuffix}${PAGE_ROUTE.noretailer}`
          )
        }
        if (tokenResp.status !== 200) {
          setIsNotFound(true)
        } else {
          setTokenResp(tokenResp)
        }
      })
      .catch(error => {
        trackError(error)
        setHasError(true)
      })
      .finally(() => setIsLoading(false))
  }, [domainPrefix, domainSuffix, history, token])

  if (isLoading) {
    return (
      <div className="vd-align-center vd-mt5">
        <LoaderSpinner />
      </div>
    )
  }

  if (isNotFound) {
    return <NotFoundPage />
  }

  if (hasError) {
    return (
      <main className="vd-flex vd-flex--align-center vd-flex--justify-center">
        <ErrorPage />
      </main>
    )
  }

  return (
    <Redirect
      to={{
        pathname: '/reset_password',
        state: {
          user_id: tokenResp?.user_id,
          username: tokenResp?.username,
          token: tokenResp?.token,
          is_initial_password_set: tokenResp?.is_initial_password_set,
        },
      }}
    />
  )
}
