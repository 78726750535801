import React from 'react'
import { TransifexManager } from './components/transifexManager/TransifexManager'

export const REACT_UTILITIES_TRANSIFEX_TAG = 'monocle-react-utilities'

const withTranslations = <P extends object>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: React.FC<React.PropsWithChildren<P>>,
  displayName?: string
): React.FC<React.PropsWithChildren<P>> => {
  const TranslatedComponent: React.FC<React.PropsWithChildren<P>> = props => {
    return (
      <TransifexManager tag={REACT_UTILITIES_TRANSIFEX_TAG}>
        <Component {...props} />
      </TransifexManager>
    )
  }
  const componentName =
    // eslint-disable-next-line i18next/no-literal-string
    displayName || Component.displayName || Component.name || 'Component'
  TranslatedComponent.displayName = componentName
  return TranslatedComponent
}

export { withTranslations }
