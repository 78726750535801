import { fetchApiJson } from '../api/fetchApiJson'
const FEATURES_ENDPOINT = '/api/2.0/features'

interface FeaturesResponse {
  [featureName: string]: boolean
}

/**
 * Get features for the current retailer.
 */
export const getFeatures = async (
  ...features: string[]
): Promise<FeaturesResponse> => {
  const noParamSupplied = features.length === 0

  if (noParamSupplied) {
    throw new Error('One or more feature names required.')
  }

  // NOTE: These params are URI encoded by default
  const queryParams = new URLSearchParams(
    features.map(flag => ['features', flag])
  ).toString()
  const requestUrl = `${FEATURES_ENDPOINT}?${queryParams}`
  const response = await fetchApiJson(requestUrl)

  return response.features
}
