export const getClientType = () => {
  const returnURL = new URLSearchParams(window.location.search).get('return')
  if (!returnURL) {
    return ''
  }
  try {
    const [_, paramString] = returnURL.split('?')
    const params = new URLSearchParams(paramString)
    return params.get('client_type') || ''
  } catch {
    return ''
  }
}
