import React, { FunctionComponent } from 'react'

import { TXProvider } from '@transifex/react'
import { useTXManager } from '../../hooks/useTXManager'

export interface TransifexManagerProps {
  tag: string
  token?: string
}

export const TransifexManager: FunctionComponent<
  React.PropsWithChildren<TransifexManagerProps>
> = ({ tag, token, children }) => {
  const txManager = useTXManager(tag, token)

  const txInstance = txManager === null ? undefined : txManager.txInstance

  return <TXProvider instance={txInstance}>{children}</TXProvider>
}
