import React from 'react'
import IMAGES_CDN_PATH from '@vend/images-ui'

export const SigninBackground: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const src = `${IMAGES_CDN_PATH}backgrounds/login-background-xseries.jpg`
  return (
    <div
      className="vd-signin-background"
      style={{ backgroundImage: `url(${src})` }}
    />
  )
}
