import { getXsrfToken } from './getXsrfToken'

/**
 * Send a request to the Vend API.
 */
export async function fetchApi(
  path: string,
  options: RequestInit = {}
): Promise<Response> {
  const response = await fetch(path, {
    credentials: 'include',
    redirect: 'error',
    ...options,
    headers: {
      ...options.headers,
      'x-xsrf-token': getXsrfToken()!,
    },
  })
  if (response.status === 401) {
    // Redirect to the signin page.
    window.location.href = '/signin?return=' + encodeURI(window.location.href)
  }
  return response
}
