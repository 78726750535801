import { useEffect, useState } from 'react'
import {
  REACT_UTILITIES_TRANSIFEX_TAG,
  useTXManager,
} from '@vend/react-utilities'
import { getNetworkInfo, getTransifexLocale, trackError } from '@vend/utilities'
import { BUSINESS_COMPONENTS_TRANSLATION_TAG } from '@vend/business-components'

export const TRANSIFEX_LOADING_TIMEOUT = 10000
const USE_DEFAULT_TOKEN = undefined

export const useRatifierTransifex = () => {
  const [locale] = useState(() => getTransifexLocale())
  const [isReady, setIsReady] = useState(false)
  const ratifierTxManager = useTXManager(
    'monocle-ratifier',
    USE_DEFAULT_TOKEN,
    [BUSINESS_COMPONENTS_TRANSLATION_TAG, REACT_UTILITIES_TRANSIFEX_TAG]
  )

  useEffect(() => {
    if (!ratifierTxManager.setCurrentLocale) {
      return
    }

    // Fetch translations by setting the current locale
    const loadingPromise = ratifierTxManager
      .setCurrentLocale(locale)
      .catch(() => {
        throw new Error(`Can't fetch translations for ${locale}`)
      })

    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(
          new Error(`Timed out attempting to load translations for ${locale}`)
        )
      }, TRANSIFEX_LOADING_TIMEOUT)
    })

    Promise.race([loadingPromise, timeoutPromise])
      .catch((e: Error) => {
        trackError(e.message, e?.cause || getNetworkInfo())
      })
      .then(() => {
        // Set html language attribute
        document
          ?.getElementsByTagName('html')[0]
          .setAttribute('lang', locale.replace('_', '-').toLowerCase())
        setIsReady(true)
      })
  }, [locale, ratifierTxManager])

  return { isReady, txInstance: ratifierTxManager.txInstance }
}
