import {
  Button,
  Checkbox,
  CodeInput,
  Modal,
  ModalActions,
  ModalBanner,
  ModalContent,
  ModalHeader,
  ModalSidebar,
} from '@lightspeed/design-system-react'
import { T, useT, UT } from '@transifex/react'
import { VendImage } from '@vend/react-utilities'
import React, { useEffect, useState } from 'react'
import { withTranslations } from '../../tx'
import { MFA } from './types'
import { getClientType } from './utils'

interface OTPModalProps {
  onClickNoAuthApp: () => void
  onComplete: (mfa: MFA) => void
  isInvalidOTP: boolean
  isSubmitting: boolean
  onClose?: () => void
  hasInternalError?: boolean
  authType?: 'signin' | 'userSwitch'
}

const OTPModalUnconnected: React.FC<OTPModalProps> = ({
  onClickNoAuthApp,
  onComplete,
  isInvalidOTP,
  hasInternalError,
  isSubmitting,
  onClose,
  authType = 'signin',
}) => {
  const t = useT()
  const isSignin = authType === 'signin'

  const [otp, setOtp] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [shouldShowError, setShouldShowError] = useState(false)

  const clientType = getClientType()
  const isMobileApp = clientType === 'ios' || clientType === 'android'

  const invalidOTPErrorMessage = t(
    'Invalid authentication code entered. Please try again.',
    {
      _comment: 'erorr message for invalid otp code entered on the OTP modal.',
    }
  )

  useEffect(() => {
    if (isSubmitting) {
      return
    }

    // clear otp if it's invalid
    if (isInvalidOTP) {
      setOtp('')
    }
    setShouldShowError(true)
  }, [isInvalidOTP, isSubmitting])

  const handleChange = (code: string, done: boolean) => {
    setOtp(code)
    setShouldShowError(false)

    if (!done) {
      return
    }

    const mfa: MFA = {
      type: 'OTP',
      password: code,
      remember_me: rememberMe,
    }
    onComplete(mfa)
  }

  const toggleRememberMe = () => setRememberMe(!rememberMe)

  const renderPrompt = () => {
    const CONTEXT =
      'Link to support article about multifactor authentication codes'
    const COMMENT =
      'Used as the {helpLink} for the multifactor authentication prompt'

    return (
      <p className="vd-p">
        {isSignin ? (
          <T
            _str={
              'To finish signing in, enter the authentication code generated by your authentication app. {helpLink}'
            }
            _context="Multifactor authentication prompt for signing in"
            helpLink={
              <UT
                _inline
                _str={`<a href="https://x-series-support.lightspeedhq.com/hc/en-us/articles/360003973336" className="vd-link" rel="noopener noreferrer" target="_blank">How do I find my authentication code?</a>`}
                _context={CONTEXT}
                _comment={COMMENT}
              />
            }
          />
        ) : (
          <T
            _str={
              'To finish user switching, enter the authentication code generated by your authentication app. {helpLink}'
            }
            _context="Multifactor authentication prompt for user switching"
            helpLink={
              <UT
                _inline
                _str={`<a href="https://x-series-support.lightspeedhq.com/hc/en-us/articles/360003973336" className="vd-link" rel="noopener noreferrer" target="_blank">How do I find my authentication code?</a>`}
                _context={CONTEXT}
                _comment={COMMENT}
              />
            }
          />
        )}
      </p>
    )
  }

  return (
    <Modal
      focusTrapActive={!isSignin}
      pageModal={isSignin}
      size={isSignin ? 'medium-with-sidebar' : 'small-with-sidebar'}
      onClose={onClose}
    >
      <ModalSidebar className="vd-flex vd-align-center">
        <VendImage
          className="mfa-modal-sidebar-image"
          relativeImagePath="signin/enter-otp-v1.svg"
          alt={t('MFA OTP image', { _context: 'image alt' })}
        />
      </ModalSidebar>

      {shouldShowError && hasInternalError && (
        <ModalBanner variant="negative">
          <p className="vd-p">
            <T
              _str={
                'Something went wrong. Please {refreshButton} to try again, or {helpLink} if this problem continues.'
              }
              _context="Multifactor authentication error message"
              refreshButton={
                <Button link onClick={() => window.location.reload()}>
                  {t('refresh', {
                    _context: 'refresh try again',
                    _comment:
                      'Used as the {refreshButton} for the multifactor authentication error message',
                  })}
                </Button>
              }
              helpLink={
                <UT
                  _inline
                  _str={`<a href="https://x-series-support.lightspeedhq.com/hc/en-us" className="vd-link" rel="noopener noreferrer" target="_blank">contact Lightspeed Retail Support</a>`}
                  _context="Contact support help link"
                  _comment="Used as the {helpLink} link for the multifactor authentication error message"
                />
              }
            />
          </p>
        </ModalBanner>
      )}

      <ModalHeader>
        {t('Enter your authentication code', { _context: 'otp modal header' })}
      </ModalHeader>

      <ModalContent>
        {renderPrompt()}

        {!isMobileApp && (
          <div className="vd-field">
            <Checkbox
              label={t('Remember me on this device for 30 days.')}
              checked={rememberMe}
              onChange={toggleRememberMe}
            />
          </div>
        )}

        <div className="vd-field otp-modal-code-input-wrapper">
          <CodeInput
            isOTP
            code={otp}
            onCodeDigitChange={handleChange}
            errorMessage={
              isInvalidOTP && shouldShowError
                ? invalidOTPErrorMessage
                : undefined
            }
          />
        </div>
      </ModalContent>

      <ModalActions className="vd-flex vd-flex--align-center vd-flex--justify-between">
        <Button link onClick={onClickNoAuthApp}>
          {t('I can’t access my authenticator app.', {
            _context: 'otp modal - button link',
          })}
        </Button>

        <Button variant="go" disabled loading={isSubmitting} name="otp_submit">
          {isSignin
            ? t('Sign in', { _context: 'Button' })
            : t('Switch user', { _context: 'Button' })}
        </Button>
      </ModalActions>
    </Modal>
  )
}

export const OTPModal = withTranslations(
  OTPModalUnconnected,
  // eslint-disable-next-line i18next/no-literal-string
  'OTPModal'
)
