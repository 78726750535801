import { fetchApi } from '@vend/utilities'

interface IsSSOEnabledResponse {
  enabled: boolean
}

export async function fetchIsSSOEnabled(
  domainPrefix: string,
  domainSuffix: string
): Promise<boolean> {
  const url = `https://${domainPrefix}${domainSuffix}/sso/enabled`
  const response = await fetch(url, {
    method: 'GET',
  })

  if (response.status !== 200) {
    throw new Error('failed to get if sso is enabled')
  }

  const data = (await response.json()) as IsSSOEnabledResponse
  return data.enabled
}

interface BeginSSOResponse {
  redirect: boolean
  url: string
}

export async function beginSSO(
  username: string,
  returnURL?: string,
  forceOutlet?: string
) {
  const response = await fetchApi('/sso/oauth/begin', {
    method: 'POST',
    body: JSON.stringify({
      username,
      return: returnURL,
      forceOutlet,
    }),
  })

  if (!response.ok) {
    throw new Error('failed to begin sso')
  }

  const data = (await response.json()) as BeginSSOResponse

  return data
}
