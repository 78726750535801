import { useT } from '@transifex/react'

export const RECAPTCHA_DEV_SITE_KEY = '6Lf9XyIUAAAAAD-LDiWredOWYvcdbtYf_wYhWKiY'
export const RECAPTCHA_PUBLIC_SITE_KEY =
  '6LfyFCIUAAAAAK7PmA78dUDFaoCfvPHRYclZgtH9'

// Having Recaptcha test key for cypress automated tests.
// https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do
export const RECAPTCHA_TEST_SITE_KEY =
  '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'

export const API_ENDPOINT = {
  Signin: '/api/1.0/signin',
  DomainPrefixCheck: '/signin/checkdomainprefix',
  RequestResetPassword: '/api/1.0/request_resetpassword',
  SetNewPassword: '/api/1.0/set_new_password',
  VerifyTokenId: '/api/1.0/verify_resetpassword_token',
  ResetPassword: '/api/1.0/resetpassword',
}

export const STEPS = {
  confirmStore: 'Confirm Store',
  credentialsAuthentication: 'Username Password Authentication',
  OTPAuthentication: 'OTP Authentication',
  recoveryCode: 'Recovery Code',
  changePassword: 'Change password',
  PasswordCompromisedAlert: 'Password Compromised Alert',
  changeCompromisedPassword: 'Change Compromised Password',
}

export type Steps = (typeof STEPS)[keyof typeof STEPS]

export const PAGE_ROUTE = {
  dashboards: '/dashboards',
  webregister: '/webregister',
  billing: '/billing',
  training: '/dashboard',
  noretailer: '/noretailer',
  signin: '/signin',
  forgotPassword: '/forgot_password',
}

export const VEND_DOMAIN_SUFFIX = {
  vendProd: '.vendhq.com',
  vendDev: '.dev.vendhq.works',
  lspdProd: '.retail.lightspeed.app',
  lspdDev: '.dev.gottagolightspeed.works',
}

export type VendDomainSuffix =
  (typeof VEND_DOMAIN_SUFFIX)[keyof typeof VEND_DOMAIN_SUFFIX]

// Error handling
export const API_ERRORS = {
  credentialsInvalid: 'Incorrect username and/or password. Please try again.',
  mfaInvalid: 'MFA invalid',
  recoveryCodeInvalid: 'Invalid recovery code',
  requirePasswordChange: 'Password change required.',
  passwordComproised: 'Password compromised',
  userDisabled: 'User disabled. Contact your IT administrator.',
  noLoginAccess: 'No login access',
}

export const VALIDATION_MESSAGES = {
  credentialsInvalid: 'credentialsInvalid',
  domainPrefixInvalid: 'domainPrefixInvalid',
  domainPrefixRequired: 'domainPrefixRequired',
  fieldsRequired: 'fieldsRequired',
  generic: 'generic',
  passwordRequired: 'passwordRequired',
  usernameRequired: 'usernameRequired',
  userDisabled: 'userDisabled',
}

type ValidationMessages =
  (typeof VALIDATION_MESSAGES)[keyof typeof VALIDATION_MESSAGES]

export const useTranslateValidationMessage = () => {
  const t = useT()
  return (key: ValidationMessages): string => {
    const genericMessage = t(
      'Something went wrong. Please refresh this page and try again.',
      {
        _comment: 'Shown when an unknown error occurrs',
      }
    )

    switch (key) {
      case VALIDATION_MESSAGES.credentialsInvalid:
        return t('Incorrect username and/or password. Please try again.', {
          _comment:
            'Shown when a user enters invalid details into the password form.',
        })

      case VALIDATION_MESSAGES.domainPrefixInvalid:
        return t('Store URL does not exist. Please try again.', {
          _comment:
            'Shown when a user enters an invalid store prefix into their browser address bar',
        })

      case VALIDATION_MESSAGES.domainPrefixRequired:
        return t('Please enter your store URL.', {
          _comment:
            'Shown to prompt a user to enter their store name when on the generic sign in page',
        })

      case VALIDATION_MESSAGES.fieldsRequired:
        return t('Please complete the highlighted fields to continue.', {
          _comment:
            'Shown when a user tries to submit the sign in form without entering both a username/password',
        })

      case VALIDATION_MESSAGES.generic:
        return genericMessage

      case VALIDATION_MESSAGES.passwordRequired:
        return t('Please enter a password.', {
          _comment:
            'Shown on the sign in form when a user does not enter their password',
        })

      case VALIDATION_MESSAGES.usernameRequired:
        return t('Please enter a username.', {
          _comment:
            'Shown on the sign in form when a user does not enter their username',
        })

      case VALIDATION_MESSAGES.userDisabled:
        return t('User disabled. Contact your IT administrator.', {
          _comment:
            'Shown when a user tries to sign in but their account has been disabled',
        })

      default:
        return genericMessage
    }
  }
}

export const useGenericErrorMessage = () => {
  const t = useT()

  return t(
    'We were unable to unable to process the request. Please try again.',
    {
      _context:
        'Generic error message displayed when a request to the backend fail',
    }
  )
}

export const GENERIC_ERROR_MESSAGE =
  'We were unable to unable to process the request. Please try again.'

export const UBE_MODULE_COMPROMISED_PASSWORD = 'Compromised Password'
