/**
 * Function for creating a cookie with a specified time limit in seconds
 * If a cookie exists in the users browser this value will be appended.
 *
 * @param name
 * @param value
 * @param seconds
 */
export const createCookie = (name: string, value: string, seconds: number) => {
  const date = new Date()
  date.setTime(date.getTime() + seconds * 1000)
  const expires = '; expires=' + date.toUTCString()

  document.cookie = name + '=' + value + expires + '; path=/'
}

// Gets the value of a cookie
export const getCookie = (name: string) =>
  document.cookie
    .split('; ')
    .find(cookie => cookie.split('=')[0] === name)
    ?.split('=')[1]
