import { trackUserEvent, UBEOptions } from './ubeTracker'

export interface PerformanceTrackingData {
  moduleName: string
  eventName?: string
  options?: UBEOptions
  metadata?: {
    [index: string]: any
  }
}

export enum PerformanceEntryType {
  Mark = 'mark',
  Measure = 'measure',
  Resource = 'resource',
  Paint = 'paint',
}

enum MarkPrefix {
  Start = 'start',
  End = 'end',
}

export function isPerformanceSupported(): boolean {
  return Boolean(
    window.performance &&
      window.performance.mark !== undefined &&
      window.performance.measure !== undefined &&
      window.performance.getEntries !== undefined
  )
}

export function startPerformanceMeasure(name: string): PerformanceEntry | null {
  return addPerformanceMark(`${MarkPrefix.Start}-${name}`)
}

export function stopPerformanceMeasure(
  name: string,
  trackingData?: PerformanceTrackingData
): PerformanceMeasure | null {
  const startName = `${MarkPrefix.Start}-${name}`
  const endName = `${MarkPrefix.End}-${name}`
  const mark = addPerformanceMark(endName)

  if (!mark) {
    return null
  }

  const measure = measurePerformance(name, startName, endName)

  clearPerformanceEntriesByName([name, startName, endName])

  // If a starting or ending mark is not found, a measure will not be created.
  if (!measure) {
    return null
  }

  if (trackingData) {
    if (!trackingData.eventName) {
      trackingData.eventName = `Performance Measure for ${measure.name}`
    }

    const { eventName, moduleName, metadata, options } = trackingData
    trackUserEvent(
      moduleName,
      eventName,
      {
        ...metadata,
        measure,
      },
      options
    )
  }

  return measure
}

export function addPerformanceMark(name: string): PerformanceEntry | null {
  if (!isPerformanceSupported()) {
    return null
  }

  window.performance.mark(name)
  return findPerformanceEntry(name, PerformanceEntryType.Mark)
}

export function findPerformanceEntry(
  name: string,
  type: PerformanceEntryType
): PerformanceEntry | null {
  const entries = window.performance.getEntriesByName(name) || []

  if (entries.length) {
    // There could be multiple entries for the same name, so get the latest
    const entry = entries[entries.length - 1]

    if (entry.entryType === type) {
      return entry
    } else {
      return null
    }
  } else {
    return null
  }
}

export function measurePerformance(
  name: string,
  startName: string,
  endName: string
): PerformanceMeasure | null {
  if (!isPerformanceSupported()) {
    return null
  }

  const hasStartMark = Boolean(
    findPerformanceEntry(startName, PerformanceEntryType.Mark)
  )
  const hasEndMark = Boolean(
    findPerformanceEntry(endName, PerformanceEntryType.Mark)
  )

  if (!hasStartMark || !hasEndMark) {
    return null
  }

  window.performance.measure(name, startName, endName)

  return findPerformanceEntry(
    name,
    PerformanceEntryType.Measure
  ) as PerformanceMeasure | null
}

export function clearPerformanceEntriesByName(namesToClear: string[] | string) {
  if (!isPerformanceSupported()) {
    return
  }

  const names = Array.isArray(namesToClear) ? namesToClear : [namesToClear]

  names.forEach(name => {
    window.performance.clearMarks(name)
    window.performance.clearMeasures(name)
  })
}
