import { format, intlFormatDistance } from 'date-fns'
import { getTransifexLocale, ISOLocaleToIETF } from '../i18n/getCurrentLocale'

/** @deprecated Use DATE_MEDIUM */
export const DATE_FORMAT: string = 'd MMM yyyy'
/** @deprecated Use DATE_TIME_LONG */
export const DATE_TIME_FORMAT: string = 'd MMM yyyy, h:mmaaa'
/** @deprecated Use DATE_TIME_MEDIUM */
export const DATE_TIME_FORMAT_FULL: string = 'E, d MMM yyyy, h:mmaaa'
/** @deprecated Use TIME_SHORT */
export const TIME_FORMAT: string = 'h:mmaaa'

// e.g. "4:23 PM"
export const TIME_SHORT: Intl.DateTimeFormatOptions = { timeStyle: 'short' }

// e.g. "Dec 20, 2020"
export const DATE_MEDIUM: Intl.DateTimeFormatOptions = { dateStyle: 'medium' }

// e.g. "Dec 20"
export const DATE_MEDIUM_PARTIAL: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
}

// e.g. "Sun, Dec 20, 2020"
export const DATE_MEDIUM_FULL: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
}

// e.g. "December 20, 2020"
export const DATE_LONG: Intl.DateTimeFormatOptions = { dateStyle: 'long' }

// e.g. "Dec 20, 2020, 4:23 PM"
export const DATE_TIME_SHORT: Intl.DateTimeFormatOptions = {
  ...DATE_MEDIUM,
  ...TIME_SHORT,
}

// e.g. "Sun, Dec 20, 2020, 4:23 PM"
export const DATE_TIME_MEDIUM: Intl.DateTimeFormatOptions = {
  ...DATE_MEDIUM_FULL,
  hour: 'numeric',
  minute: 'numeric',
}

// e.g. "December 20, 2020 at 4:23 PM"
export const DATE_TIME_LONG: Intl.DateTimeFormatOptions = {
  ...DATE_LONG,
  ...TIME_SHORT,
}

const intlFormatDateTime = (
  someDate: Date,
  formatOptions: Intl.DateTimeFormatOptions = DATE_TIME_SHORT
): string => {
  const locale = getLocaleForFormatDate()
  return new Intl.DateTimeFormat(locale, formatOptions).format(someDate)
}

/*
 * formatDateTime formats date and time following the guidelines "Dates and times" of product content.
 * https://helios.lightspeedhq.com/document/57#/formatting/dates-and-times
 */
export const formatDateTime = (
  someDate: Date | string,
  formatOptions: Intl.DateTimeFormatOptions | string = DATE_TIME_SHORT
) => {
  const date: Date =
    typeof someDate === 'string' ? new Date(someDate) : someDate
  // check string type "formatOptions" for detecting deprecated formats
  if (typeof formatOptions === 'string') {
    return format(date, formatOptions)
  } else {
    return intlFormatDateTime(date, formatOptions)
  }
}

export const formatDateDistance = (
  fromDate: Date,
  toDate: Date = new Date()
): string => {
  const locale = getLocaleForFormatDate()
  return intlFormatDistance(fromDate, toDate, { locale })
}

export function getLocaleForFormatDate() {
  const txLocale = getTransifexLocale()
  return ISOLocaleToIETF(txLocale)
}
