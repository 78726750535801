import {
  Button,
  ErrorMessage,
  FieldLabel,
  TextInput,
} from '@lightspeed/design-system-react'
import { useT } from '@transifex/react'
import React, { useState } from 'react'
import {
  VALIDATION_MESSAGES,
  useTranslateValidationMessage,
} from '../../../../constants/constants'
import { beginSSO } from '../../../../utils/sso'

interface SSOLoginProps {
  showCredentailsForm: (username: string) => void
  setErrorBannerMsg: (error: string) => void
}

export const SSOLogin: React.FC<SSOLoginProps> = ({
  showCredentailsForm,
  setErrorBannerMsg,
}) => {
  const t = useT()
  const translateValidation = useTranslateValidationMessage()

  let usernameParamInReturnURL = ''
  const urlSearchQuery = new URLSearchParams(window.location.search)
  const returnParam = urlSearchQuery.get('return') || ''
  if (returnParam && returnParam.includes('?')) {
    const returnURLQueryParams = returnParam.split('?')[1]
    const returnURLParams = new URLSearchParams(returnURLQueryParams)

    // If it's present, parse it, and check for a 'username' query parameter in that URL.
    // We'll use it to populate the username input during the OAuth2 flow.
    usernameParamInReturnURL = returnURLParams.get('username') || ''
  }
  const usernameParam = urlSearchQuery.get('username') || ''

  const usernameInputDisabled =
    usernameParamInReturnURL !== '' || usernameParam !== ''

  const [username, setUsername] = useState(
    usernameParamInReturnURL || usernameParam
  )
  const [validationErr, setValidationErr] = useState<undefined | string>()

  const handleLogin = async () => {
    if (username.trim() === '') {
      const errMsg = translateValidation(VALIDATION_MESSAGES.usernameRequired)
      setValidationErr(errMsg)
      return
    }

    const urlParams = new URLSearchParams(window.location.search)
    const returnParam = urlParams.get('return')
    const forceOutletParam = urlParams.get('forceOutlet')

    try {
      const response = await beginSSO(
        username,
        returnParam ?? undefined,
        forceOutletParam ?? undefined
      )
      if (!response.redirect) {
        showCredentailsForm(username)
        return
      }

      window.location.href = response.url
    } catch {
      setErrorBannerMsg(
        t('Something went wrong. Contact your IT administrator.')
      )
    }
  }

  return (
    <>
      <div className="vd-field">
        <FieldLabel label={t('Username')}>
          <TextInput
            value={username}
            name="username"
            autoCorrect="off"
            autoFocus
            autoComplete="username"
            autoCapitalize="off"
            onChange={e => {
              setUsername(e.target.value)
              setValidationErr(undefined)
            }}
            disabled={usernameInputDisabled}
            placeholder={t('Enter your username', {})}
            data-testid="userName"
            hasError={!!validationErr}
            onKeyDown={k => {
              if (k.key === 'Enter') {
                k.preventDefault()
                handleLogin()
              }
            }}
          />
        </FieldLabel>
      </div>
      {validationErr && <ErrorMessage>{validationErr}</ErrorMessage>}
      <div className="vd-align-right vr-btn-wrap">
        <Button
          data-testid="login"
          variant="go"
          type="submit"
          onClick={handleLogin}
          name="login"
        >
          {t('Log In', { _context: 'Button' })}
        </Button>
      </div>
    </>
  )
}
