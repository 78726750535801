import { MFA } from '@vend/business-components'
import { getXsrfToken } from '@vend/utilities'
import { API_ENDPOINT } from '../constants/constants'

export interface CredentialsValidationResult {
  reason: string
  valid_credentials: boolean
  redirect: string | false
  require_captcha: boolean
  enabled_mfa: EnabledMFA | null
}

export enum MFAType {
  OTP = 'OTP',
}

export interface EnabledMFA {
  type: MFAType
}

export interface DomainPrefixCheckResult {
  valid: boolean
  store_name: string
}

export interface InputCredentials {
  domainPrefix?: string
  username?: string
  password?: string
  'g-recaptcha-response'?: string | null
  return?: string
  mfa?: MFA | null
  bypass_hibp?: boolean
  new_password?: string
}

export interface RequestResetPasswordInput {
  username: string
  'g-recaptcha-response'?: string | null
}

export interface RequestResetPasswordResponse {
  success: boolean
  email_exists: boolean
  require_captcha: boolean
  reason: string
}

export interface SetNewPasswordInput {
  username: string
  old_password: string
  new_password: string
  'g-recaptcha-response'?: string | null
}

export interface SetNewPasswordResponse {
  status: number
  require_captcha: boolean
  error: string
}

export interface VerifyTokenIDInput {
  token_id: string
}

export interface VerifyTokenIDResponse {
  status: number
  user_id: string
  username: string
  token: string
  is_initial_password_set: boolean
}

export interface ResetPasswordInput {
  user_id: string
  token: string
  password: string
  is_initial_password_set: boolean
  'g-recaptcha-response'?: string | null
}

export interface ResetPasswordResponse {
  status: number
  require_captcha: boolean
  error: string
  signup_token: string
}

async function signinUserWithVend(
  inputCredentials: InputCredentials
): Promise<CredentialsValidationResult> {
  const xsrfToken = getXsrfToken()
  const headers: { 'Content-Type': string; 'x-xsrf-token'?: string } = {
    'Content-Type': 'application/json',
  }

  if (xsrfToken) {
    headers['x-xsrf-token'] = xsrfToken
  }

  const response = await fetch(API_ENDPOINT.Signin, {
    method: 'POST',
    headers,
    credentials: 'same-origin',
    redirect: 'error',
    body: JSON.stringify(inputCredentials),
  })

  return response.json()
}

export async function signinUser(
  inputCredentials: InputCredentials
): Promise<CredentialsValidationResult> {
  // If credentials are missing then we are trying to see if the user is already logged in.
  if (
    !inputCredentials.username ||
    !inputCredentials.password ||
    !inputCredentials.domainPrefix
  ) {
    return signinUserWithVend(inputCredentials)
  }

  return signinUserWithVend(inputCredentials)
}

export async function checkDomainPrefix(
  domain: string
): Promise<DomainPrefixCheckResult> {
  const response = await fetch(API_ENDPOINT.DomainPrefixCheck, {
    method: 'POST',
    redirect: 'error',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: `domain=${domain}`,
  })
  return response.json()
}

export async function requestResetPassword(
  input: RequestResetPasswordInput
): Promise<RequestResetPasswordResponse> {
  const response = await fetch(API_ENDPOINT.RequestResetPassword, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(input),
  })
  return response.json()
}

export async function setNewPassword(
  input: SetNewPasswordInput
): Promise<SetNewPasswordResponse> {
  const response = await fetch(API_ENDPOINT.SetNewPassword, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(input),
  })
  const resJson: SetNewPasswordResponse = await response.json()
  resJson.status = response.status

  return resJson
}

export async function verifyTokenId(
  input: VerifyTokenIDInput
): Promise<VerifyTokenIDResponse> {
  const response = await fetch(API_ENDPOINT.VerifyTokenId, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(input),
  })

  const resJson: VerifyTokenIDResponse = await response.json()
  resJson.status = response.status

  return resJson
}

export async function resetPassword(
  input: ResetPasswordInput
): Promise<ResetPasswordResponse> {
  const response = await fetch(API_ENDPOINT.ResetPassword, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(input),
  })

  const resJson: ResetPasswordResponse = await response.json()
  resJson.status = response.status

  return resJson
}
