// Note: we return `undefined` values for browsers that don't support the Network Information API
// See https://caniuse.com/#feat=netinfo
export interface NetworkInfo {
  /* The effective bandwidth estimate in megabits per second */
  downlink?: number
  /* The type of connection */
  effectiveType?: 'slow-2g' | '2g' | '3g' | '4g'
}

/**
 * Util for retrieving network information using the Network Information API.
 * See https://developer.mozilla.org/en-US/docs/Web/API/Network_Information_API.
 */
export const getNetworkInfo = (): NetworkInfo | undefined => {
  // We use `any` here as window.navigator is different depending on the browser version
  const navigator: any = window.navigator
  const connection = navigator
    ? navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection ||
      {}
    : {}
  const downlink = connection.downlink
  const effectiveType = connection.effectiveType
  let info: NetworkInfo = {}

  if (downlink) {
    info = {
      downlink,
    }
  }

  if (effectiveType) {
    info = {
      ...info,
      effectiveType,
    }
  }

  const hasInfo = Object.keys(info).length > 0

  return hasInfo ? info : undefined
}
