import { ToastNotifications } from '@lightspeed/design-system-react'
import { useEffect } from 'react'
import {
  useTranslateValidationMessage,
  VALIDATION_MESSAGES,
} from '../constants/constants'

export const useDisplayLSIDCallbackError = () => {
  const translateValidation = useTranslateValidationMessage()

  useEffect(() => {
    const urlSearchQuery = window.location.search
    const params = new URLSearchParams(urlSearchQuery)

    const callbackError = params.get('callbackError')
    if (!callbackError) {
      return
    }

    ToastNotifications.negative(
      translateValidation(VALIDATION_MESSAGES.generic)
    )

    // we want to remove this callbackError from the url so
    // that it is not propograted to any other pages
    params.delete('callbackError')

    window.history.replaceState(null, '', `?${params.toString()}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
