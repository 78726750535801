import {
  Button,
  ErrorMessage,
  LoaderSpinner,
  Modal,
  ModalActions,
  ModalBanner,
  ModalContent,
  ModalHeader,
  TextInput,
  ToastNotifications,
} from '@lightspeed/design-system-react'
import { ErrorPage } from '@vend/react-utilities'
import { trackError } from '@vend/utilities'
import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useT } from '@transifex/react'
import { useHistory } from 'react-router'
import {
  PAGE_ROUTE,
  useGenericErrorMessage,
} from '../../../constants/constants'
import {
  checkDomainPrefix,
  RequestResetPasswordResponse,
  RequestResetPasswordInput,
  requestResetPassword,
} from '../../../utils/api'
import { getVendDomainSuffix } from '../../../utils/util'
import { Topbar } from '../../common/Topbar/Topbar'
import { useRecaptcha } from '../../../utils/useRecaptcha'

enum STEPS {
  request = 0,
  response,
  adminResponse,
}

export const ForgotPassword = () => {
  const t = useT()
  const GENERIC_ERROR_MESSAGE = useGenericErrorMessage()

  const history = useHistory()

  const domainSuffix = getVendDomainSuffix(window.location.href)
  // Extract the prefix on the environments domain, e.g. catsandhats.vendhq.com
  const domainPrefix = window.location.hostname.substring(
    0,
    window.location.hostname.indexOf(domainSuffix)
  )

  const [isLoading, setIsLoading] = useState(true)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [username, setUsername] = useState('')
  const [showMissingPasswordError, setShowMissingPasswordError] =
    useState(false)
  const [activeStep, setActiveStep] = useState(STEPS.request)
  const [showSSOEnabledError, setShowSSOEnabledError] = useState(false)

  const {
    showRecaptcha,
    recaptchaKey,
    recaptchaRef,
    getRecaptchaToken,
    handleRecaptchaStatusUpdate,
  } = useRecaptcha()

  useEffect(() => {
    checkDomainPrefix(domainPrefix)
      .then((response: { valid: any }) => {
        if (!response.valid) {
          window.location.assign(
            `https://secure${domainSuffix}${PAGE_ROUTE.noretailer}`
          )
        }
      })
      .catch((error: string | Error) => {
        trackError(error)
        setHasError(true)
      })
      .finally(() => setIsLoading(false))
  }, [domainPrefix, domainSuffix])

  if (hasError) {
    return (
      <section className="vr-container">
        <Topbar />
        <main className="vd-flex vd-flex--align-center vd-flex--justify-center">
          <ErrorPage />
        </main>
      </section>
    )
  }

  if (isLoading) {
    return (
      <div className="vd-align-center vd-mt5">
        <LoaderSpinner />
      </div>
    )
  }

  const redirectToSignin = () => {
    history.push(PAGE_ROUTE.signin)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value)
    setShowMissingPasswordError(false)
  }

  const handleResponse = (response: RequestResetPasswordResponse) => {
    if (response.success) {
      response.email_exists
        ? setActiveStep(STEPS.response)
        : setActiveStep(STEPS.adminResponse)
      return
    }
    handleRecaptchaStatusUpdate(response.require_captcha)

    if (response.reason === 'SSO enabled') {
      setShowSSOEnabledError(true)
      return
    }
    ToastNotifications.negative(t(GENERIC_ERROR_MESSAGE))
  }

  const handleSubmit = async () => {
    if (username.trim().length === 0) {
      setShowMissingPasswordError(true)
      return
    }

    setIsSubmiting(true)
    try {
      const input: RequestResetPasswordInput = { username: username.trim() }
      if (showRecaptcha) {
        input['g-recaptcha-response'] = await getRecaptchaToken()
      }

      const response = await requestResetPassword(input)
      handleResponse(response)
    } catch (error: any) {
      trackError(error)
      ToastNotifications.negative(t(GENERIC_ERROR_MESSAGE))
    } finally {
      setIsSubmiting(false)
    }
  }

  const handleDone = () => {
    setShowMissingPasswordError(false)
    setUsername('')
    setActiveStep(STEPS.request)
  }

  const renderRequestView = () => {
    return (
      <Modal focusTrapActive={true} pageModal size="small">
        {showSSOEnabledError && (
          <ModalBanner variant="negative">
            {t('Unable to reset password. Contact your IT administrator.', {
              _context: 'Request reset password error message',
            })}
          </ModalBanner>
        )}
        <ModalHeader>
          <div className="vd-flex vd-flex--align-center">
            <div className="vd-text-heading">
              {t('Reset your Lightspeed Retail password?')}
            </div>
          </div>
        </ModalHeader>

        <ModalContent>
          <div className="vd-text vd-pb6">
            {t('Enter your username to receive a link by email.')}
          </div>
          <div className="vd-field">
            <div className="vd-label">{t('Username')}</div>
            <div className="vd-value">
              <TextInput
                value={username}
                name="username"
                autoCorrect="off"
                autoFocus
                autoComplete="username"
                autoCapitalize="off"
                onChange={handleChange}
                placeholder={t('Enter your username')}
                hasError={showMissingPasswordError}
              />
              {showMissingPasswordError && (
                <ErrorMessage> {t('Please enter a username')} </ErrorMessage>
              )}
            </div>
          </div>
          <div className="vd-align-right vr-btn-wrap">
            <Button
              variant="supplementary"
              onClick={redirectToSignin}
              className="vd-mr2"
            >
              {t('Cancel')}
            </Button>
            <Button
              variant="go"
              type="submit"
              loading={isSubmiting}
              onClick={handleSubmit}
            >
              {t('Send email')}
            </Button>
          </div>
        </ModalContent>
      </Modal>
    )
  }

  const renderResponseView = (admin?: string) => {
    return (
      <Modal focusTrapActive={true} pageModal size="small">
        {admin ? (
          <>
            <ModalHeader>
              <div className="vd-flex vd-flex--align-center">
                <div className="vd-text-heading">
                  {t('Email sent to primary admin')}
                </div>
              </div>
            </ModalHeader>
            <ModalContent>
              {t(
                'There’s no email address associated with your username. We sent the reset password instructions to the primary admin of your Lightspeed Retail store. They’ll be able to reset your password for you.'
              )}
            </ModalContent>
          </>
        ) : (
          <>
            <ModalHeader>
              <div className="vd-flex vd-flex--align-center">
                <div className="vd-text-heading">{t('Email sent')}</div>
              </div>
            </ModalHeader>
            <ModalContent>
              {t('Use the link in the email to reset your password.')}
            </ModalContent>
          </>
        )}
        <ModalActions>
          <div className="vd-align-right">
            <Button variant="go" type="submit" onClick={handleDone}>
              {t('Done')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
    )
  }

  const renderContent = () => {
    switch (activeStep) {
      case STEPS.request:
        return renderRequestView()
      case STEPS.response:
        return renderResponseView()
      case STEPS.adminResponse:
        return renderResponseView('admin')
      default:
        return renderRequestView()
    }
  }

  return (
    <section className="vr-container">
      <main className="vd-flex vd-flex--align-center vd-flex--justify-center vr-main">
        <div className="vd-flex vd-flex--justify-center">
          {showRecaptcha && (
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={recaptchaKey}
            />
          )}
          {renderContent()}
        </div>
      </main>
    </section>
  )
}
