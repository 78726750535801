const isObject = (obj: any) => obj === Object(obj)

export const snakeToCamel = (data: object | string, depth?: number) => {
  if (isObject(data)) {
    if (typeof depth === 'undefined') {
      depth = 1
    }
    return _processKeys(data as object, _camelize, depth)
  } else {
    return _camelize(data as string)
  }
}

export const camelToSnake = (
  data: object | string,
  depth?: number
): object | string => {
  if (isObject(data)) {
    if (typeof depth === 'undefined') {
      depth = 1
    }
    return _processKeys(data as object, _snakelize, depth)
  } else {
    return _snakelize(data as string)
  }
}

// snakelize a string formed in underscore
function _snakelize(key: string): string {
  const separator = '_'
  const split = /(?=[A-Z])/

  // Used to insert an underscore before groups of numbers e.g. to support custom_field_123
  const matchNumbers = /(\d+)/
  const prefixWithSeparator = `${separator}$&`

  return key
    .split(split)
    .join(separator)
    .replace(matchNumbers, prefixWithSeparator)
    .toLowerCase()
}

// camelize a string formed in underscore
function _camelize(key: string): string {
  key = key.replace(/[-_\s]+(.)?/g, (match, ch) => {
    return ch ? ch.toUpperCase() : ''
  })
  // Ensure 1st char is always lowercase
  return key.substr(0, 1).toLowerCase() + key.substr(1)
}

// camelize/snakelize keys of an object
// @param {number} depth to which level of keys should it process
function _processKeys(
  obj: Record<string, any>,
  processer: (s: string) => string,
  depth: number
): any {
  if (depth === 0 || !isObject(obj)) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(v => _processKeys(v, processer, depth - 1))
  }

  const result: Record<string, any> = {}
  const keys = Object.keys(obj)

  keys.forEach(key => {
    result[processer(key)] = _processKeys(obj[key], processer, depth - 1)
  })

  return result
}
