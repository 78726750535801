declare global {
  interface Window {
    isSPARoute: undefined | ((path: string) => boolean)
    previousSPAUrl: undefined | string
  }
}

export type NavigateOptions = {
  /** Will modifiy the current history entry instead */
  replace?: boolean
  /** Forces the use of the history APIs when `spaNavigate` is not present */
  useHistory?: boolean
  openInNewWindow?: boolean
}

export type NavigateFn = (to: string, options?: NavigateOptions) => void

/**
 * If you need to navigate programmatically (like after a form submits),
 * this function gives you an API to do so.
 *
 * It also takes care of either performing a `single-spa` routing
 * (if matching an Single-SPA application)
 * or use the `location` or `history native APIs`
 */
export const navigate: NavigateFn = (to, options = {}) => {
  const destination = new URL(to, window.location.origin)
  let { replace, useHistory, openInNewWindow } = options
  const { isSPARoute } = window

  if (openInNewWindow) {
    window.open(to, '_blank')
    return
  }

  if (!useHistory && isSPARoute) useHistory = isSPARoute(to)

  if (isDestinationSameAsCurrent(destination)) {
    window.location.hash = destination.hash
  } else if (useHistory) {
    window.previousSPAUrl = window.location.href
    replace
      ? window.history.replaceState(null, '', to)
      : window.history.pushState(null, '', to)
  } else {
    replace ? window.location.replace(to) : window.location.assign(to)
  }
}

//
// Private
//

function isDestinationSameAsCurrent(destination: URL): boolean {
  return (
    destination.pathname === window.location.pathname &&
    destination.search === window.location.search
  )
}
