import { trackError } from '@vend/utilities'
import { useEffect, useState } from 'react'
import { PAGE_ROUTE } from '../constants/constants'
import { checkDomainPrefix, InputCredentials, signinUser } from './api'
import { fetchIsSSOEnabled } from './sso'
import { getVendDomainSuffix } from './util'

const domainSuffix = getVendDomainSuffix(window.location.href)
// Extract the prefix on the environments domain, e.g. catsandhats.vendhq.com
const domainPrefix = window.location.hostname.substring(
  0,
  window.location.hostname.indexOf(domainSuffix)
)
const isFromSecureDomain = domainPrefix === 'secure'
const NO_RETAILER_URL = `https://secure${domainSuffix}${PAGE_ROUTE.noretailer}`

export const useAuthentication = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [storeName, setStoreName] = useState('')
  const [isSSOEnabled, setIsSSOEnabled] = useState(false)
  const [hasError, setHasError] = useState(false)

  const checkIfAlreadyAuthenticatedUserPromise = () => {
    const urlSearchQuery = new URLSearchParams(window.location.search)
    const returnURL = urlSearchQuery.get('return')
    const inputCredentials: InputCredentials = {}
    if (returnURL) {
      inputCredentials.return = returnURL
    }
    return signinUser(inputCredentials)
  }

  useEffect(() => {
    if (isFromSecureDomain) {
      return
    }

    if (domainPrefix.indexOf('www.') !== -1) {
      window.location.host =
        domainPrefix.replace('www.', '') + `${domainSuffix}`
      setIsLoading(false)
      return
    }

    Promise.all([
      checkIfAlreadyAuthenticatedUserPromise(),
      checkDomainPrefix(domainPrefix),
      fetchIsSSOEnabled(domainPrefix, domainSuffix),
    ])
      .then(results => {
        const { valid_credentials: validCredentials, redirect } = results[0]
        if (validCredentials) {
          window.location.assign(redirect || PAGE_ROUTE.webregister)
          return
        }
        const { valid, store_name: store } = results[1]
        if (valid) {
          setStoreName(store)
        } else {
          window.location.assign(NO_RETAILER_URL)
        }

        const isSSOEnabled = results[2]
        setIsSSOEnabled(isSSOEnabled)
      })
      .catch(error => {
        trackError(error)
        setHasError(true)
      })
      .finally(() => setIsLoading(false))
  }, [])

  return { isLoading, storeName, isSSOEnabled, hasError }
}
