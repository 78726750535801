import {
  createNativeInstance,
  TxNative,
  ITranslationConfig,
} from '@transifex/native'
import {
  saveToStorage,
  getFromStorage,
} from '../preferences/localStorageWrapper'
import { MissingTranslationPolicy } from './missingTranslationPolicy'

// Default token is for the X-Series-Web-Monocle project
const DEFAULT_TOKEN = '1/af8dd5b9fec79f9ea0fb7f7dab4842261ff5d5ec'
const DISABLE_TRANSLATION_FEATURE_FLAG = 'disable_translation'
const TX_FEATURES_STORAGE_KEY = 'tx_features_storage_key'
const SELF_HOSTED_TRANSIFEX_URL_LIGHTSPEED =
  'https://transifex-cds-uoBiexohtahveeY6boh.retail.lightspeed.app'

const edgeFeatures = [DISABLE_TRANSLATION_FEATURE_FLAG]

const getEdgeFeatures = async (features: string[]) => {
  const fallbackFeatures =
    getFromStorage<Record<string, boolean>>(TX_FEATURES_STORAGE_KEY) || {}
  try {
    return await fetch(
      `/api/2.0/features/edge?features=${features.join('&features=')}`
    )
      .then(response => response.json())
      .then(result => {
        const features = {
          ...fallbackFeatures,
          ...result.features,
        }
        saveToStorage(TX_FEATURES_STORAGE_KEY, features)
        return features
      })
  } catch {
    return fallbackFeatures
  }
}

const edgeFeaturesPromise = getEdgeFeatures(edgeFeatures)

const getInstanceConfig = (token: string, tag: string) => {
  const settings: ITranslationConfig = {
    token,
    filterTags: tag,
    missingPolicy: new MissingTranslationPolicy(),
    cdsHost: SELF_HOSTED_TRANSIFEX_URL_LIGHTSPEED,
  }
  return settings
}

const isTranslationDisabledPromise = edgeFeaturesPromise.then(
  features => features[DISABLE_TRANSLATION_FEATURE_FLAG]
)

const setCurrentLocaleForInstances = (
  instances: TxNative[],
  locale: string
) => {
  return isTranslationDisabledPromise.then(isTranslationDisabled => {
    if (isTranslationDisabled) {
      return Promise.resolve([])
    }

    return Promise.all(
      instances.map(instance => instance.setCurrentLocale(locale))
    )
  })
}

export const createTxInstanceCache = () => {
  const loadedInstances: Record<string, TxNative> = {}

  let currentLocale: string

  /**
   * Get the TXInstance for a given filterTag
   */
  const getTxInstance = (tag: string, token?: string) => {
    let instance = loadedInstances[tag]
    if (!instance) {
      // Creates and initializes new instance
      instance = createNativeInstance(
        getInstanceConfig(token || DEFAULT_TOKEN, tag)
      )
      loadedInstances[tag] = instance
      if (currentLocale) {
        setCurrentLocaleForInstances([instance], currentLocale)
      }
    }

    return instance
  }

  /**
   * Set the language for all TXInstance managed by this cache
   */
  const setCurrentLocale = (locale: string) => {
    currentLocale = locale

    return isTranslationDisabledPromise.then(isTranslationDisabled => {
      if (isTranslationDisabled) {
        return Promise.resolve([])
      }

      return setCurrentLocaleForInstances(
        Object.values(loadedInstances),
        locale
      )
    })
  }

  /**
   * Get the current locale
   */
  const getCurrentLocale = () => currentLocale

  /**
   * Directly add a tx instance to the txManager.
   */
  const addTxInstance = (
    instance: TxNative,
    tag: string,
    token?: string,
    key?: string
  ) => {
    instance.init(getInstanceConfig(token || DEFAULT_TOKEN, tag))

    loadedInstances[key || tag] = instance

    if (currentLocale) {
      setCurrentLocaleForInstances([instance], currentLocale)
    }
  }

  return { getTxInstance, addTxInstance, setCurrentLocale, getCurrentLocale }
}
