import { isProduction } from '@vend/utilities'
import { VEND_DOMAIN_SUFFIX } from '../constants/constants'

const NO_REDIRECT_FLAG = 'no_redirection'

const allDomains = [
  VEND_DOMAIN_SUFFIX.lspdDev,
  VEND_DOMAIN_SUFFIX.lspdProd,
  VEND_DOMAIN_SUFFIX.vendDev,
  VEND_DOMAIN_SUFFIX.vendProd,
]

const redirectableDomains = [
  VEND_DOMAIN_SUFFIX.vendDev,
  VEND_DOMAIN_SUFFIX.vendProd,
]

const isNoRedirect = () => {
  const search = new URLSearchParams(window.location.search)
  return Boolean(search.get(NO_REDIRECT_FLAG))
}

const getCurrentDomain = () =>
  allDomains.find(domain => window.location.hostname.includes(domain)) ||
  VEND_DOMAIN_SUFFIX.vendProd

const isRedirectableDomain = () => {
  const currentDomain = getCurrentDomain()
  return redirectableDomains.some(domain => currentDomain.includes(domain))
}

const getDomainForEnvironment = () => {
  if (isProduction(window.location.toString())) {
    return VEND_DOMAIN_SUFFIX.lspdProd
  }

  return VEND_DOMAIN_SUFFIX.lspdDev
}

export const getRetailDomain = () =>
  isNoRedirect() || !isRedirectableDomain()
    ? getCurrentDomain()
    : getDomainForEnvironment()
