import { fetchApi } from './fetchApi'

/**
 * Send a request to the Vend API and parse the response as JSON.
 */
export async function fetchApiJson(
  path: string,
  options: RequestInit = {}
): Promise<any> {
  const response = await fetchApi(path, options)
  return response.json()
}
