import { getXsrfToken } from '../api/getXsrfToken'
import { changeLocale } from './locale'
import { toggleTheme } from './theme'
import { getXsrf } from './xsrf'

/**
 * Allows for changing app settings via the window object instead of having to change browser settings.
 */
type DevTools = {
  changeLocale: (local: string) => void
  toggleTheme: () => void
  getXsrf: () => ReturnType<typeof getXsrfToken>
}

declare global {
  interface Window {
    __devtools: DevTools
  }
}

/**
 * Attach devtool functions to window object.
 */
const setupDevTools = () => {
  window.__devtools = {
    changeLocale,
    toggleTheme,
    getXsrf,
  }
}

if (window && window.__devtools === undefined) {
  setupDevTools()
}
