import { UT } from '@transifex/react'
import { VendImage } from '@vend/react-utilities'
import React from 'react'

export const Topbar: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <header className="nv-topnav vd-topbar vr-topbar">
      <div className="vd-nav-item">
        <div className="vd-logo-container" data-testid="topbar-logo">
          <VendImage
            width="120px"
            className="vd-logo"
            relativeImagePath="logos/lightspeed-logo-white-v3.svg"
          />
        </div>
      </div>

      <div className="nv-topnav-content">
        <div className="vd-nav-item">
          <UT
            _inline
            _str={
              // eslint-disable-next-line prefer-smart-quotes/prefer
              '<a class="vd-nav-item-action" href="https://x-series-support.lightspeedhq.com/hc/en-us" target="_blank">' +
              // eslint-disable-next-line prefer-smart-quotes/prefer
              '<span class="vd-nav-item-label">Help</span></a>'
            }
            _context={'help link button'}
          />
        </div>
      </div>
    </header>
  )
}
