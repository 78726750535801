import flatten from 'lodash/flatten'

/**
 * The source language and the fallback language
 * for unsupported languages
 */
export const DEFAULT_LOCALE = 'en_US'

/**
 * Supported locales by language
 *
 * Important: the first locale of an array is the fallback
 * of the language.
 */
export const SUPPORTED_LOCALES_BY_LANGUAGE: Record<string, string[]> = {
  en: [DEFAULT_LOCALE],
  fr: ['fr_FR', 'fr_CA'],
  nl: ['nl_NL'],
}

/**
 * Is the locale supported?
 */
export const isLocaleSupported = (language: string): boolean => {
  return flatten(Object.values(SUPPORTED_LOCALES_BY_LANGUAGE)).includes(
    language
  )
}
