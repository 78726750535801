import { trackError } from '../tracking/errorTracker'
import { fetchApi } from '../api/fetchApi'

type Imports = Record<string, string>

interface ImportMap {
  imports: Imports
}

const importMapURL = '/import-map.json'

let importMapPromise: Promise<Imports> | null = null

/**
 *
 * @returns Get a list of the module identifiers available in the import map
 */
export const getImports: () => Promise<Record<string, string>> = () => {
  if (!importMapPromise) {
    importMapPromise = fetchApi(importMapURL)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch import map`)
        }
        return response.json() as Promise<ImportMap>
      })
      .then(importMap => importMap?.imports || {})
      .catch(e => {
        trackError(e)
        return {}
      })
  }
  return importMapPromise
}

/**
 * Get the module for the given identifier
 */
export const getModule = <T extends System.Module = System.Module>(
  identifier: string
): Promise<T> => System.import(identifier)

const mountedCSS: Record<string, { count: number; element: HTMLLinkElement }> =
  {}

/**
 * If there is any CSS associated with a module, load it
 */
export const loadModuleCSS = async (identifier: string) => {
  const imports = await getImports()

  const cssIdentifier = `${identifier}-css`
  if (!imports[cssIdentifier]) {
    return
  }

  return new Promise<void>((resolve, reject) => {
    if (mountedCSS[identifier]) {
      mountedCSS[identifier].count++
      return resolve()
    }
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = imports[cssIdentifier]
    link.onload = () => resolve()
    link.onerror = reject
    mountedCSS[identifier] = { count: 1, element: link }
    document.head.appendChild(link)
  })
}

/**
 * Unload the CSS associated with a module
 */
export const unloadModuleCSS = (identifier: string) => {
  if (!mountedCSS[identifier] || mountedCSS[identifier].count < 1) {
    return
  }
  mountedCSS[identifier].count--
  if (mountedCSS[identifier].count === 0) {
    document.head.removeChild(mountedCSS[identifier].element)
    delete mountedCSS[identifier]
  }
}
