import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from '@lightspeed/design-system-react'
import { useT } from '@transifex/react'
import { trackUserEvent } from '@vend/utilities'
import React, { useState } from 'react'
import { withTranslations } from '../../tx'
import { UBE_MODULE_COMPROMISED_PASSWORD } from './constants'
import { NewPassword } from './NewPassword'

interface ChangeCompromisedPasswordModalProps {
  onNewPasswordChange: (password: string) => void
  isSubmiting: boolean
  onCancel: () => void
  onSubmit: () => void
  onClose?: () => void
  authType?: 'signin' | 'userSwitch'
}

const ChangeCompromisedPasswordModalUnconnected: React.FC<
  ChangeCompromisedPasswordModalProps
> = ({
  onNewPasswordChange,
  isSubmiting,
  onCancel,
  onSubmit,
  onClose,
  authType = 'signin',
}) => {
  const t = useT()
  // local copy of the new password for validation
  const [newPassword, setNewPassword] = useState('')
  const [hasClickedSubmit, sethasClickedSubmit] = useState(false)

  const onPasswordChange = (password: string, isValid: boolean) => {
    if (isValid) {
      onNewPasswordChange(password)
      setNewPassword(password)
      return
    }

    setNewPassword('')
  }

  const handleCancel = () => {
    // clear new password
    setNewPassword('')
    onNewPasswordChange('')

    onCancel()
  }

  const handleSubmit = async () => {
    sethasClickedSubmit(true)

    if (newPassword === '') {
      return
    }

    trackUserEvent(UBE_MODULE_COMPROMISED_PASSWORD, 'Set New Password Clicked')

    onSubmit()
  }

  return (
    <Modal
      focusTrapActive={true}
      size="small"
      pageModal={authType === 'signin'}
      onClose={onClose}
    >
      <ModalHeader>
        <div className="vd-flex vd-flex--align-center">
          <div className="vd-text-heading">
            {t('Change your password', {
              _context: 'change compromised password modal -  header',
            })}
          </div>
        </div>
      </ModalHeader>

      <ModalContent>
        <div className="vd-field">
          <div className="vd-value">
            <NewPassword
              onPasswordChange={onPasswordChange}
              hasClickedSubmit={hasClickedSubmit}
            />
          </div>
        </div>
      </ModalContent>
      <ModalActions>
        <Button
          className="vd-mr2"
          variant="supplementary"
          onClick={handleCancel}
        >
          {t('Cancel', {
            _context: 'change compromised password modal - button',
          })}
        </Button>
        <Button
          variant="go"
          type="submit"
          loading={isSubmiting}
          onClick={handleSubmit}
        >
          {t('Save new password', {
            _context: 'change compromised password modal - button',
          })}
        </Button>
      </ModalActions>
    </Modal>
  )
}

export const ChangeCompromisedPasswordModal = withTranslations(
  ChangeCompromisedPasswordModalUnconnected,
  // eslint-disable-next-line i18next/no-literal-string
  'ChangeCompromisedPasswordModal'
)
