import React from 'react'
import { Button } from '@lightspeed/design-system-react'
import { useT, UT } from '@transifex/react'
import { withTranslations } from '../../tx'
import { VendImage } from '../vendImage/VendImage'

const refreshPage = () => window.location.reload()

const ErrorPageUnconnected: React.FC<React.PropsWithChildren<{}>> = () => {
  const t = useT()
  return (
    <section data-testid="error-page" className="vd-error-main">
      <div className="vd-flex vd-flex--column vd-flex--align-center vd-mt10">
        <VendImage relativeImagePath="error/cannot-fetch-data-v3.svg" />
        <section className="vd-section">
          <div className="vd-section-wrap">
            <h1 className="vd-header vd-header--section">
              {t('Sorry, we couldn’t load this page.', { _context: 'Header' })}
            </h1>
            <p className="vd-p">
              {t('Please refresh this page and try again.')}
            </p>
          </div>
        </section>
        <Button variant="supplementary" onClick={refreshPage}>
          {t('Refresh Page', { _context: 'Button' })}
        </Button>
        <p className="vd-flex vd-flex--align-center">
          <UT
            _inline
            _str={`If this problem continues, check Lightspeed Retail’s <a href="https://status.vendhq.com" target="_blank" rel="noopener noreferrer" className="vd-link vd-link--secondary" >System Status</a> for real-time information about our system performance.`}
          />
        </p>
      </div>
    </section>
  )
}

export const ErrorPage = withTranslations(ErrorPageUnconnected, 'ErrorPage')
