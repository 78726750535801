import {
  Button,
  ErrorMessage,
  FieldLabel,
  Modal,
  ModalContent,
  ModalHeader,
  ModalSidebar,
  TextInput,
} from '@lightspeed/design-system-react'
import { T, useT, UT } from '@transifex/react'
import { VendImage } from '@vend/react-utilities'
import React, { useEffect, useState } from 'react'
import { withTranslations } from '../../tx'
import { MFA } from './types'

interface RecoveryCodeModalProps {
  onBack: () => void
  onSubmit: (mfa: MFA) => void
  isSubmitting: boolean
  isInvalidCode: boolean
  onClose?: () => void
  authType?: 'signin' | 'userSwitch'
}

const recoveryCodeRegexp: RegExp = /^[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{4}$/
const recoveryCodeLength: number = 14

const validateRecoveryCode = (code: string): boolean => {
  return code.length === recoveryCodeLength && recoveryCodeRegexp.test(code)
}

const RecoveryCodeModalUnconnected: React.FC<RecoveryCodeModalProps> = ({
  onBack,
  onSubmit,
  isSubmitting,
  isInvalidCode,
  onClose,
  authType = 'signin',
}) => {
  const t = useT()
  const isSignin = authType === 'signin'

  const invalidCodeErrMessage = t(
    'Invalid recovery code entered. Please try again.',
    {
      _context:
        'erorr message for invalid recovery code entered on the recovery code modal',
    }
  )

  const [recoveryCode, setRecoveryCode] = useState('')
  const [showInvalidCodeError, setShowInvalidCodeError] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(true)

  useEffect(() => {
    if (isSubmitting) {
      return
    }

    setShowInvalidCodeError(isInvalidCode)
  }, [isInvalidCode, isSubmitting])

  const onRecoveryCodeChange = (code: string): void => {
    setShowInvalidCodeError(false)

    if (validateRecoveryCode(code)) {
      setRecoveryCode(code)
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    if (!validateRecoveryCode(recoveryCode)) {
      setShowInvalidCodeError(true)
      return
    }

    const mfaInput: MFA = {
      type: 'OTP',
      recovery_code: recoveryCode,
    }

    onSubmit(mfaInput)
  }

  return (
    <Modal
      focusTrapActive={!isSignin}
      pageModal={isSignin}
      size={isSignin ? 'medium-with-sidebar' : 'small-with-sidebar'}
      onClose={onClose}
    >
      <ModalSidebar className="vd-flex vd-align-center">
        <VendImage
          className="mfa-modal-sidebar-image"
          relativeImagePath="signin/enter_recovery_code-v1.svg"
          alt={t('recovery code image', { _context: 'image alt' })}
        />
      </ModalSidebar>

      <ModalHeader>
        {isSignin
          ? t('Enter a recovery code to finish signing in', {
              _context: 'recovery code modal header',
            })
          : t('Enter a recovery code to finish user switching', {
              _context: 'recovery code modal header',
            })}
      </ModalHeader>

      <ModalContent>
        <div className="vd-body--no-scroll">
          <div className="vd-p">
            <T
              _str="If you have lost access to your authentication app, enter one of your recovery codes. This code can’t be used a second time. {recoveryCodeArticleLink}"
              recoveryCodeArticleLink={
                <UT
                  _str={
                    // eslint-disable-next-line prefer-smart-quotes/prefer
                    '<a href="https://support.vendhq.com/hc/en-us/articles/360003973336" class="vd-link" rel="noopener noreferrer" target="_blank">What are recovery codes?</a>'
                  }
                  _comment="Link for recoveryCodeArticleLink"
                />
              }
            />
          </div>

          <form onSubmit={handleSubmit}>
            <div className="vd-field">
              <FieldLabel
                htmlFor="recovery-code"
                label={t('Recovery Code', {
                  _context: 'Label for the form input to enter a recovery code',
                })}
              />
              <TextInput
                autoFocus
                name="recovery-code"
                placeholder={t('Enter your recovery code')}
                hasError={showInvalidCodeError && Boolean(recoveryCode)}
                onChange={e => onRecoveryCodeChange(e.target.value)}
              />
              {showInvalidCodeError && Boolean(recoveryCode) && (
                <ErrorMessage>{invalidCodeErrMessage}</ErrorMessage>
              )}
            </div>

            <div className="vd-flex vd-flex--align-center vd-flex--justify-between vd-pt3">
              <UT
                _str={
                  // eslint-disable-next-line prefer-smart-quotes/prefer
                  '<a href="https://support.vendhq.com/hc/en-us/articles/360003973336" class="vd-link" rel="noopener noreferrer" target="_blank">I can’t access my recovery codes.</a>'
                }
              />

              <div className="vd-btn-group">
                <Button
                  className="vd-mr2"
                  variant="supplementary"
                  onClick={onBack}
                >
                  {t('Back')}
                </Button>
                <Button
                  variant="go"
                  type="submit"
                  disabled={submitDisabled && !isSubmitting}
                  loading={isSubmitting}
                >
                  {isSignin
                    ? t('Sign in', { _context: 'recovery code modal - button' })
                    : t('Switch user', {
                        _context: 'recovery code modal - button',
                      })}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </ModalContent>
    </Modal>
  )
}

export const RecoveryCodeModal = withTranslations(
  RecoveryCodeModalUnconnected,
  // eslint-disable-next-line i18next/no-literal-string
  'RecoveryCodeModal'
)
