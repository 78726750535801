import { ApiRetailer, ApiUser } from '@vend/types'

export const getDatadogUser = (user: ApiUser, retailer: ApiRetailer) => {
  return {
    id: user.id,
    name: `Employee at ${retailer.domain_prefix}`,
    accountType: user.account_type,
    retailerId: retailer.id,
    retailerDomain: retailer.domain_prefix,
    accountStatus: retailer.account_status,
    language: navigator.language,
  }
}
