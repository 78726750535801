import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum'
import { ApiRetailer, ApiUser } from '@vend/types'
import { isDevelopment } from '../environment'
import { getRetailer } from '../data/retailer'
import { getUser } from '../data/user'
import { getFeatures } from '../features/getFeatures'
import {
  getFromStorage,
  saveToStorage,
} from '../preferences/localStorageWrapper'
import { getDatadogUser } from './datadogCommon'

export interface InitDatadogParams {
  applicationName: string
  applicationId: string
  clientToken: string
  authenticated?: boolean
}

type DatadogRum = typeof datadogRum
type UserPromise = Promise<[ApiUser, ApiRetailer]>

declare let DD_RUM: DatadogRum

const SAMPLE_RATE = 10
const PREMIUM_SAMPLE_RATE = 100
const FEATURE_CACHE_KEY = 'dd_feature_cache'
const ALLOWED_TRACING_ORIGINS: string[] = [`https://${window.location.host}`]

const getSessionReplayFeatureKey = (applicationName: string) =>
  `${applicationName}_session_replay`
const getAllSessionReplayFeatureKey = (applicationName: string) =>
  `${applicationName}_all_session_replay`

const getFeatureCache = () =>
  getFromStorage<Record<string, boolean>>(FEATURE_CACHE_KEY) || {}

const getFromFeatureCache = (applicationName: string, featureName: string) => {
  const features = getFeatureCache()
  return Boolean(features && features[featureName])
}

const setFeatureForKey = (
  featureCache: Record<string, boolean>,
  features: Record<string, boolean>,
  key: string
) => {
  const updatedCache = { ...featureCache }
  if (key in features) {
    updatedCache[key] = features[key]
  } else if (key in updatedCache) {
    delete updatedCache[key]
  }

  return updatedCache
}

const initFeatureCache = (applicationName: string) => {
  getFeatures(
    getSessionReplayFeatureKey(applicationName),
    getAllSessionReplayFeatureKey(applicationName)
  ).then(features => {
    const featureCache = getFeatureCache()
    const sessionKey = getSessionReplayFeatureKey(applicationName)
    const sessionAllKey = getAllSessionReplayFeatureKey(applicationName)

    let updatedCache = setFeatureForKey(featureCache, features, sessionKey)
    updatedCache = setFeatureForKey(updatedCache, features, sessionAllKey)

    saveToStorage(FEATURE_CACHE_KEY, updatedCache)
  })
}

const shouldSessionReplay = (applicationName: string) =>
  getFromFeatureCache(
    applicationName,
    getSessionReplayFeatureKey(applicationName)
  )

const shouldAllSessionReplay = (applicationName: string) =>
  getFromFeatureCache(
    applicationName,
    getAllSessionReplayFeatureKey(applicationName)
  )

const ddPromise: Promise<void> = new Promise((resolve, reject) => {
  if ('DD_RUM' in window) {
    DD_RUM.onReady(() => {
      resolve()
    })
  } else {
    reject(new Error('Datadog not set'))
  }
})

ddPromise.catch(() => {})

const getDataDogConfig = ({
  applicationName,
  clientToken,
  applicationId,
}: InitDatadogParams): RumInitConfiguration => {
  const gatherAll = shouldAllSessionReplay(applicationName)
  return {
    clientToken,
    applicationId,
    site: 'us5.datadoghq.com',
    service: applicationName,
    env: 'production',
    sampleRate: gatherAll ? 100 : SAMPLE_RATE,
    premiumSampleRate: gatherAll ? 100 : PREMIUM_SAMPLE_RATE,
    silentMultipleInit: true,
    trackInteractions: true,
    trackFrustrations: true,
    allowedTracingOrigins: ALLOWED_TRACING_ORIGINS,
    defaultPrivacyLevel: 'mask-user-input',
    version: window?.process?.env?.REACT_APP_GIT_SHORT || '',
  }
}

const runDatadog = ({
  applicationName,
  applicationId,
  clientToken,
}: InitDatadogParams) => {
  DD_RUM.init(getDataDogConfig({ applicationName, clientToken, applicationId }))
}

const setDatadogUser = (userPromise: UserPromise) =>
  userPromise.then(([user, retailer]) => {
    DD_RUM.setUser(getDatadogUser(user, retailer))
  })

/**
 * Initialise Datadog monitoring for the application.
 * See /docs/datadog for details
 */
export const initDatadog = ({
  applicationName,
  clientToken,
  applicationId,
  authenticated = true,
}: InitDatadogParams) => {
  if (isDevelopment(window.location.href)) {
    return
  }

  let userPromise: UserPromise

  if (authenticated) {
    initFeatureCache(applicationName)
    userPromise = Promise.all([getUser(), getRetailer()])
  }

  ddPromise.then(() => {
    runDatadog({ applicationName, clientToken, applicationId })

    if (userPromise) {
      setDatadogUser(userPromise)
    }

    if (
      shouldSessionReplay(applicationName) ||
      shouldAllSessionReplay(applicationName)
    ) {
      DD_RUM.startSessionReplayRecording()
    }
  })
}
