import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router'
import { Signin } from '../views/Signin/Signin'
import { ForgotPassword } from '../views/ForgotPassword/ForgotPassword'
import { ResetPassword } from '../views/ResetPassword/ResetPassword'
import { ResetPasswordVerifyToken } from '../views/ResetPassword/ResetPasswordVerifyToken'

export const Routes: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Signin} key="signin" path="/signin" exact />
        <Route
          component={ForgotPassword}
          key="forgot-password"
          path="/forgot_password"
          exact
        />
        <Route
          component={ResetPasswordVerifyToken}
          key="reset-password-verify-token"
          path="/reset_password/:token"
          exact
        />
        <Route
          component={ResetPassword}
          key="reset-password"
          path="/reset_password"
          exact
        />
      </Switch>
    </BrowserRouter>
  )
}
