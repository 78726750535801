/* eslint-disable no-console */
import type {
  RaygunOptions,
  RaygunPayload,
  RaygunStatic,
  RaygunV2,
} from 'raygun4js'
import type { RumGlobal } from '@datadog/browser-rum'
import { isDevelopment } from '../environment'
import { SystemConfig } from '../types/SystemConfig'

/**
 * Global instance of Raygun
 */
declare let rg4js: RaygunV2
/**
 * Global instance of Datadog
 */
declare let DD_RUM: RumGlobal

const domainPrefix = window.location.hostname

/**
 * Micro-frontend instance of Raygun
 *
 * Note: The first micro-frontend that loads will use the global Raygun instance,
 * all others will get a new instance. This enables sending errors to different Raygun projects.
 */
let raygun: RaygunStatic

export function getRaygunInstance(callback: (raygun: RaygunStatic) => void) {
  if (typeof rg4js !== 'function') {
    console.error('Cannot get Raygun instance without loading raygun4js script')
    return
  }

  // Ensures we don't construct a new Raygun instance for the same micro-frontend
  if (raygun) {
    callback(raygun)
    return
  }

  // @ts-ignore
  rg4js('getRaygunInstance', (globalRaygunInstance: RaygunStatic) => {
    // Ensures when there are two getRaygunInstance(s) in flight that we don't
    // assign a new unitialized Raygun instance over the initialized one
    // and nothing works
    const newRaygunInstance = (
      globalRaygunInstance as any
    ).Utilities.isApiKeyConfigured()
      ? globalRaygunInstance.constructNewRaygun()
      : globalRaygunInstance
    raygun = raygun ?? newRaygunInstance

    callback(raygun)
  })
}

export interface ErrorTrackingOptions extends SystemConfig {
  onBeforeSend?: (payload: RaygunPayload) => RaygunPayload | boolean
  retailerId?: string
  saveIfOffline?: boolean
  retailerAccountStatus?: string
}

/*
 * Configure error tracking with Raygun. Ensure you add the following inside the <head></head> tag of your index.html:

<script type="text/javascript">
  !function(a,b,c,d,e,f,g,h){a.RaygunObject=e,a[e]=a[e]||function(){
  (a[e].o=a[e].o||[]).push(arguments)},f=b.createElement(c),g=b.getElementsByTagName(c)[0],
  f.async=1,f.src=d,g.parentNode.insertBefore(f,g),h=a.onerror,a.onerror=function(b,c,d,f,g){
  h&&h(b,c,d,f,g),g||(g=new Error(b)),a[e].q=a[e].q||[],a[e].q.push({
  e:g})}}(window,document,"script","//cdn.raygun.io/raygun4js/raygun.min.js","rg4js");
</script>

 * See https://raygun.com/docs/languages/javascript for details and additional docs.
 */
export function initRaygun(
  apiKey: string,
  options: Partial<ErrorTrackingOptions> = {},
  raygunOptions: RaygunOptions = {}
): void {
  getRaygunInstance(raygun => {
    raygun.init(apiKey, raygunOptions)
    rg4js('enableCrashReporting', true)
  })
  configureRaygun(options)
}

export function configureRaygun(options: Partial<ErrorTrackingOptions>): void {
  getRaygunInstance(raygun => {
    if (options.systemVersion) {
      raygun.setVersion(options.systemVersion)
    }

    if (options.retailerId) {
      raygun.setUser(
        options.retailerId, // identifier
        undefined, // isAnonymous
        domainPrefix, // email (hijacking this prop to pass domain prefix so we can search for it in Raygun)
        undefined, // firstName
        undefined, // fullName
        options.deviceFingerprint // uuid
      )
    }

    if (options.retailerAccountStatus) {
      raygun.withTags([`account_status_${options.retailerAccountStatus}`])
    }

    if (isDevelopment(window.location.href, options.environment)) {
      raygun.onBeforeSend(payload => {
        console.debug('[Error Tracking] Error tracking disabled in development')

        if (options.onBeforeSend) {
          console.debug('[Error Tracking] Triggering onBeforeSend...')
          options.onBeforeSend(payload)
        }

        console.error('[Error Tracking]', payload)
        return false
      })
    } else if (options.onBeforeSend) {
      raygun.onBeforeSend(options.onBeforeSend)
    }

    if (options.saveIfOffline) {
      raygun.saveIfOffline(options.saveIfOffline)
    }
  })
}

/**
 * Send an explicit error to the error tracker.
 *
 * @param error The error to track. Recommend constructing with `new Error(msg)` to get stack trace
 * @param customData Optional additional custom data to send with the error
 */

export function trackError(
  error: Error | string,
  customData?: any,
  tags?: any
): void {
  const trackedError = typeof error === 'string' ? new Error(error) : error

  console.debug('[Error Tracking] Sending error to tracker', {
    customData,
    error: trackedError,
    tags,
  })

  // Track error with Raygun
  getRaygunInstance(raygun => {
    raygun.send(trackedError, customData, tags)
  })

  // Track error with Datadog
  if ('DD_RUM' in window) {
    DD_RUM.onReady(() => {
      DD_RUM.addError(trackedError, { customData, tags })
    })
  }
}
