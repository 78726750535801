import { saveToStorage, I18N_STORAGE_KEY } from '../i18n/getCurrentLocale'

/**
 * Change the app locale.
 *
 * @param {string} locale - locale to use with Transifex
 * @param {string} localeForIntl - the locale to use with Intl APIs
 *
 * @example
 * (in browser console) __devtools.changeLocale('fr-CA')
 */
export const changeLocale = (locale_: string, localeForIntl_?: string) => {
  const locale = locale_.replace('-', '_')
  // use localeForIntl if passed in, otherwise fallback to the transifex locale
  let localeForIntl = (localeForIntl_ || locale).replace('_', '-')

  const invalidLocaleError = () =>
    // eslint-disable-next-line no-console
    console.error(
      `Invalid locale given. Some examples of valid locales: ['en-US', 'fr_CA']`
    )

  try {
    // Validate locale by checking if we can get the canonical locale name
    // @ts-ignore
    localeForIntl = Intl.getCanonicalLocales(localeForIntl)[0]
  } catch (err) {
    return invalidLocaleError()
  }

  const localeRegex = /^[a-z]{2}-[A-Z]{2}$/
  if (!localeForIntl.match(localeRegex)) {
    return invalidLocaleError()
  }

  saveToStorage(I18N_STORAGE_KEY, { currentLocale: locale, localeForIntl })
  window.location.reload()
}
