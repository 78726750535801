import {
  Modal,
  ModalHeader,
  ModalContent,
  Button,
  ToastNotifications,
} from '@lightspeed/design-system-react'
import { NotFoundPage } from '@vend/react-utilities'
import React, { useState } from 'react'
import { useT } from '@transifex/react'
import { NewPassword } from '@vend/business-components'
import {
  InputCredentials,
  setNewPassword,
  SetNewPasswordInput,
  SetNewPasswordResponse,
} from '../../../../utils/api'
import { useGenericErrorMessage } from '../../../../constants/constants'

interface ChangePasswordProps {
  inputCredentials: InputCredentials
  showFirstStep: () => void
  requireRecaptcha: boolean
  onRecaptchaStatusUpdate: (required: boolean) => void
  getRecaptchaToken: () => Promise<string>
}

export const ChangePassword: React.FC<
  React.PropsWithChildren<ChangePasswordProps>
> = ({
  inputCredentials,
  showFirstStep,
  requireRecaptcha,
  onRecaptchaStatusUpdate,
  getRecaptchaToken,
}) => {
  const t = useT()
  const GENERIC_ERROR_MESSAGE = useGenericErrorMessage()
  const [password, setPassword] = useState('')
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [hasClickedSubmit, sethasClickedSubmit] = useState(false)

  const onPasswordChange = (password: string, isValid: boolean) => {
    if (isValid) {
      setPassword(password)
      return
    }

    setPassword('')
  }

  const handleResponse = (response: SetNewPasswordResponse) => {
    if (response.status === 200) {
      // redirect back to signin
      showFirstStep()
      return
    }

    onRecaptchaStatusUpdate(response.require_captcha)
    ToastNotifications.negative(GENERIC_ERROR_MESSAGE)
  }

  const handleSubmit = async () => {
    sethasClickedSubmit(true)

    if (password === '') {
      return
    }

    setIsSubmiting(true)
    try {
      const payload: SetNewPasswordInput = {
        new_password: password,
        old_password: inputCredentials.password!,
        username: inputCredentials.username!,
      }
      if (requireRecaptcha) {
        payload['g-recaptcha-response'] = await getRecaptchaToken()
      }

      const response = await setNewPassword(payload)
      handleResponse(response)
    } catch (error: any) {
      ToastNotifications.negative(GENERIC_ERROR_MESSAGE)
    } finally {
      setIsSubmiting(false)
    }
  }

  if (!inputCredentials.username || !inputCredentials.password) {
    return <NotFoundPage />
  }

  return (
    <Modal focusTrapActive={true} pageModal size="small">
      <ModalHeader>
        <div className="vd-flex vd-flex--align-center">
          <div className="vd-text-heading">{t('Create a new password')}</div>
        </div>
      </ModalHeader>

      <ModalContent>
        <div className="vd-text vd-pb6">
          {t(
            'You’ve logged in with a temporary password. Create a new password for “{username}” to continue.',
            { username: inputCredentials.username }
          )}
        </div>
        <div className="vd-field">
          <div className="vd-value">
            <NewPassword
              onPasswordChange={onPasswordChange}
              hasClickedSubmit={hasClickedSubmit}
            />
          </div>
        </div>
        <div className="vd-align-right vr-btn-wrap">
          <Button
            variant="go"
            type="submit"
            loading={isSubmiting}
            onClick={handleSubmit}
          >
            {t('Create password')}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  )
}
