import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { getRecaptchaKey } from './util'

export const useRecaptcha = () => {
  // This function is basically a hack to make recaptcha work more than one time.
  // It has to be called more than once to avoid innocuous console errors.
  // See https://github.com/dozoisch/react-google-recaptcha/issues
  // @ts-ignore
  const resetRecaptcha = () => window.grecaptcha?.reset()
  const [showRecaptcha, setShowRecaptcha] = useState<boolean>(false)
  const recaptchaKey = getRecaptchaKey()
  const recaptchaRef = React.createRef<ReCAPTCHA>()
  const handleRecaptchaStatusUpdate = (required: boolean) => {
    resetRecaptcha()
    setShowRecaptcha(required)
  }

  const getRecaptchaToken = async (): Promise<string> => {
    try {
      const token = await recaptchaRef.current!.executeAsync()
      resetRecaptcha()
      if (!token) {
        return ''
      }

      return token
    } catch (error) {
      return ''
    }
  }

  return {
    showRecaptcha,
    recaptchaKey,
    recaptchaRef,
    getRecaptchaToken,
    handleRecaptchaStatusUpdate,
  }
}
