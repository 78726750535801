import React, { Component, PropsWithChildren, ReactNode } from 'react'
import { trackError } from '@vend/utilities'
import { ErrorPage } from '../errorPage/ErrorPage'

interface ErrorBoundaryState {
  hasError: boolean
}

interface ErrorBoundaryProps {
  errorComponent?: ReactNode
}

export class ErrorBoundary extends Component<
  PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  public state = { hasError: false }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ hasError: true })
    trackError(error, errorInfo)
  }

  public render() {
    if (!this.state.hasError) {
      return this.props.children
    }

    if (this.props.errorComponent) {
      return this.props.errorComponent
    }

    return <ErrorPage />
  }
}
