import { isDevelopment } from '../environment'

// Store warnings already shown; prevents multiple logs showing for the same string.
const loggedWarnings: string[] = []

/**
 * Highlights strings that are missing translations from Transifex.
 */
export class MissingTranslationPolicy {
  public handle(sourceString: string, localeCode: string) {
    const warningKey = `${localeCode}_${sourceString.replace(/\s/g, '')}`
    if (
      isDevelopment(window.location.href) &&
      !sourceString.includes('__txnative__') &&
      !loggedWarnings.includes(warningKey)
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        `[TRANSIFEX] Missing translation for "${sourceString}" (${localeCode}).`
      )
      loggedWarnings.push(warningKey)
    }
    // Always return the source string to display
    return sourceString
  }
}
