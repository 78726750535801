import { detect } from 'detect-browser'

export interface BrowserDetails {
  name?: string
  version?: string | null
}

let detectedInfo: ReturnType<typeof detect>

const getDetectedInfo = (): typeof detectedInfo => {
  if (detectedInfo === undefined) {
    detectedInfo = detect()
  }
  return detectedInfo
}

/**
 * Helper to retrieve current browser details
 */
export const getBrowserDetails = (): BrowserDetails | undefined => {
  const info = getDetectedInfo()
  if (!info) {
    return undefined
  }

  return {
    name: info.name,
    version: info.version,
  }
}

/**
 * Determines if the browser is on an iOS device.
 */
export const isIOS = (): boolean => {
  const info = getDetectedInfo()
  return Boolean(info && info.os === 'iOS')
}
